import { ContextEnvironmentConfigInterface, Environment } from '../config.model';
import { ContextConfig } from './spine-context.config';

class ContextEnvironmentConfig extends ContextConfig implements ContextEnvironmentConfigInterface {
  environment: Environment = 'dev';
  kaltura = {
    partnerId: '2396981',
		uiconfId: '42494131'
  };
  aoMembershipUrl = 'https://aofoundation2023--int.sandbox.my.site.com/ao/s/membership-purchase?ContextId=AO%20Spine';
  moreEventsLink = 'https://int.aofoundation.org/courses-and-events/course-finder';
}

const contextConfig = new ContextEnvironmentConfig();
export default contextConfig;
