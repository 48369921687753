import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';

import { VerificationStatus } from './verification.model';
// services
import { AuthService } from './auth/auth.service';
import { SchemaValidatorService } from './schema-validator.service';
import { VerificationService } from './verification.service';

// models
import {
  GroupByIdRequestParams,
  GroupByIdResponse,
  GroupsListRequestParams,
  GroupsListResponse,
  GroupPostsListResponse,
  LeaveGroupMember,
  LeaveGroupRequest,
  LeaveGroupResponse,
  GroupMembersResponse,
  GroupMembersRequestParams,
  GroupPostByIdRequestParams,
  PostByIdResponse,
  UpdateGroupResponse,
  SaveGroupData,
  CreateGroupResponse,
  RequestToJoinGroupRequestParams,
  RequestToJoinGroupRequest,
  RequestToJoinGroupResponse,
  JoinGroupRequestParams,
  JoinGroupMember,
  JoinGroupRequest,
  JoinGroupResponse,
  AcceptRejectGroupInvitationRequestParams,
  AcceptRejectGroupInvitationRequest,
  AcceptRejectGroupInvitationResponse,
  CreatePostRequestParams,
  CreatePostRequest,
  CreatePostResponse,
  UpdatePostRequestParams,
  UpdatePostRequest,
  UpdatePostResponse,
  DeletePostRequestParams,
  DeletePostResponse,
  RemovePostAttachmentResponse,
  RemovePostAttachmentRequestParams,
  ReportAsAbuseObjectType,
  ReportAsAbuseResponse,
  ReportAsAbuseRequest,
  ReportAsAbuseRequestParams,
  MemberPendingInvitationsResponse,
  CaseFileType,
  PostType,
  CaseClassificationsResponse,
  CaseClassificationsRequestParams,
  LeaveGroupMemberStatus,
  DeleteGroupResponse,
  UpdateGroupMemberResponse,
  SearchGroupParticipantsResponse,
  ReportGroupResponse,
  GetGroupRecommendsResponse,
  ShareObjectInMultipleGroupsRequestParams,
  PostsListResponse,
  PostsListRequestParams,
  GroupMemberPendingRequestsResponse,
  ShareObjectInMultipleGroupsEventsRequestData,
  ShareObjectInMultipleGroupsEventsResponse,
  PinPostResponse,
  PinPostRequestParams,
  UnpinPostResponse,
  UnpinPostRequestParams,
  GroupsStreamResponse,
  PaginationContextRequestParams,
  ParentType,
  UserRecentPublicContributionsResponse,
  UserRecentPublicContributionsParams,
  SendReminderForGroupInvitationResponse,
  GetLikesResponse,
  PostsDetailsByPostIdsResponse, LikeUnlikeResponse, LikeUnlikeRequest, LikeUnlikeRequestParams
} from '../services/yeti-protocol/chatter-api';
import { toAuthRequestParams } from './auth/logic/auth-logic.utils';
import appConfig from 'src/config/config';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';
import { ErrorResponse } from './yeti-protocol/error';

export interface ChatterApiServiceConfig {
  serverUrl: string;
  backendIonicUrl: string;
}

export enum UserRecentPublicContributionsFilter {
  ALL = 'all',
  CASE = 'case',
  POST = 'post'
}

export interface ParsedServerError {
  errorMessage: string;
  groupId: string;
  errorCode: string;
}

const DEFAULT_GROUPS_FEED_COUNT = 15;
export const NO_ACCESS = 'noAccessForNonParticipants';

@Injectable({
  providedIn: 'root'
})
export class ChatterApiService {

  config: ChatterApiServiceConfig = {
    serverUrl: appConfig.chatterUrl,
    backendIonicUrl: appConfig.backendUrlIonic
  }

  private defaultStart = 0;
  private defaultGroupListCount = 15;
  private defaultGroupPostsCount = 15;
  private defaultGroupMembersCount = 15;
  private defaultMemberPendingInvitations = 9;
  private defaultGroupMemberPendingRequests = 9;
  private defaultSearchGroupParticipantsCount = 10;
  private defaultPostsCount = 15;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private httpClient: HttpClient,
    private authService: AuthService,
    private schemaValidator: SchemaValidatorService,
    private verificationService: VerificationService
  ) {
  }

  getMemberGroups(start: number = this.defaultStart, count: number = this.defaultGroupListCount): Promise<GroupsListResponse> {
    const params: GroupsListRequestParams = {
      appId: this._appId,
      onlymember: true,
      start,
      count
    };

    return this.fetchGroups(params);
  }

  getMemberPendingInvitations(start: number = this.defaultStart,
    count: number = this.defaultMemberPendingInvitations): Promise<MemberPendingInvitationsResponse> {
    const getRequestUrl = `${this.config.serverUrl}groups/memberPendingInvitations`;

    const params: PaginationContextRequestParams = {
      appId: this._appId,
      start,
      count
    }

    return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<MemberPendingInvitationsResponse>('MemberPendingInvitationsResponse')
    ));
  }

  getGroupMemberPendingRequests(
    start: number = this.defaultStart,
    count: number = this.defaultGroupMemberPendingRequests
  ): Promise<GroupMemberPendingRequestsResponse> {
    const getRequestUrl = `${this.config.serverUrl}groups/pendingRequestsForMember`;

    const params: PaginationContextRequestParams = {
      appId: this._appId,
      start,
      count
    }

    return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<GroupMemberPendingRequestsResponse>('GroupMemberPendingRequestsResponse')
    ));
  }

  getRecommendedGroups(start: number = this.defaultStart, count: number = this.defaultGroupListCount): Promise<GroupsListResponse> {
    const getRequestUrl = `${this.config.backendIonicUrl}recommendation/groups`

    const params: PaginationContextRequestParams = {
      appId: this._appId,
      start,
      count
    }

    return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<GroupsListResponse>('GroupsListResponse')
    ));
  }

  getGroupById(groupId: string): Promise<GroupByIdResponse> {

    return this.authService.isSignedIn().then(isSignedIn => {

      let getGroupObservable: Observable<any>;
      const getRequestUrl = `${this.config.serverUrl}groups/${groupId}`;

      const params: GroupByIdRequestParams = {
        appId: this._appId
      };

      if (isSignedIn) {
        getGroupObservable = this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) });
      } else {
        getGroupObservable = this.httpClient.get(getRequestUrl, { params: params as any });
      }

      return firstValueFrom(getGroupObservable.pipe(
        this.schemaValidator.isValidOperator<GroupByIdResponse>('GroupByIdResponse')
      ));
    })
  }

  getPostsInGroup(groupId: string, start: number = this.defaultStart,
    count: number = this.defaultGroupPostsCount): Promise<GroupPostsListResponse> {

    return this.authService.asserIsSignedIn().then(() => {
      const getRequestUrl = `${this.config.serverUrl}groups/${groupId}/posts`;

      const params: PaginationContextRequestParams = {
        appId: this._appId,
        start,
        count
      };

      return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
        this.schemaValidator.isValidOperator<GroupPostsListResponse>('GroupPostsListResponse')
      ));
    });
  }

  getPostsByPostIds(postIds: string[]): Promise<PostsDetailsByPostIdsResponse> {
    return this.authService.asserIsSignedIn().then(() => {
      const getRequestUrl = `${this.config.serverUrl}/posts/postsDetailsByPostIds?appId=${this._appId}`;
      return firstValueFrom(this.authService.securePost(getRequestUrl, {postIds}));
    });
  }

  leaveGroup(groupId: string, memberId: string, status: LeaveGroupMemberStatus): Promise<LeaveGroupResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const leaveGroupUrl = `${this.config.serverUrl}groups/${groupId}/members`;

      const requestParams = {
        appId: this._appId,
      };

      const leaveGroupMember: LeaveGroupMember = {
        userId: memberId,
        status: status
      };

      const deleteData: LeaveGroupRequest = {
        members: [leaveGroupMember]
      };

      return firstValueFrom(this.authService.secureDelete<LeaveGroupRequest, LeaveGroupResponse>(leaveGroupUrl, deleteData, {
        params: toAuthRequestParams(requestParams)
      }).pipe(
        this.schemaValidator.isValidOperator<LeaveGroupResponse>('LeaveGroupResponse')
      ));
    });

  }

  deleteGroup(groupId: string): Promise<DeleteGroupResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const deleteGroupUrl = `${this.config.serverUrl}groups/${groupId}`;

      const options = {
        params: toAuthRequestParams({
          appId: this._appId,
        })
      };

      return firstValueFrom(this.authService.secureDelete<any, DeleteGroupResponse>(deleteGroupUrl, null, options).pipe(
        this.schemaValidator.isValidOperator<DeleteGroupResponse>('DeleteGroupResponse')
      ));
    });
  }

  getGroupMembers(groupId: string,
    start: number = this.defaultStart,
    count: number = this.defaultGroupMembersCount,
    excludedUserIds?: Array<string>
  ): Promise<GroupMembersResponse> {

    return this.authService.isSignedIn().then(isSignedIn => {

      let getGroupMembersObservable: Observable<any>;
      const getRequestUrl = `${this.config.serverUrl}groups/${groupId}/members`;
      const params: GroupMembersRequestParams = {
        appId: this._appId,
        start,
        count,
        excludedUserIds: excludedUserIds || []
      };

      if (isSignedIn) {
        getGroupMembersObservable = this.authService.secureGet(getRequestUrl, {
          params: toAuthRequestParams(params)
        });
      } else {
        getGroupMembersObservable = this.httpClient.get(getRequestUrl, {
          params: params as any
        });
      }

      return firstValueFrom(getGroupMembersObservable.pipe(
        this.schemaValidator.isValidOperator<GroupMembersResponse>('GroupMembersResponse')
      ));
    });
  }

  getGroupTopContributors(groupId: string,
    start: number = this.defaultStart,
    count: number = this.defaultGroupMembersCount
  ): Promise<GroupMembersResponse> {

    return this.authService.isSignedIn().then(isSignedIn => {

      let getGroupTopContributorsObservable: Observable<any>;
      const getRequestUrl = `${this.config.serverUrl}groups/${groupId}/topContributors`;
      const params: GroupMembersRequestParams = {
        appId: this._appId,
        start,
        count,
      };

      if (isSignedIn) {
        getGroupTopContributorsObservable = this.authService.secureGet(getRequestUrl, {
          params: toAuthRequestParams(params)
        });
      } else {
        getGroupTopContributorsObservable = this.httpClient.get(getRequestUrl, {
          params: params as any
        });
      }

      return firstValueFrom(getGroupTopContributorsObservable.pipe(
        this.schemaValidator.isValidOperator<GroupMembersResponse>('GroupMembersResponse')
      ));
    });
  }
  getRecommendedTopContributors(
    start: number = this.defaultStart,
    count: number = this.defaultGroupMembersCount,
  ): Promise<GroupMembersResponse> {

    return this.authService.isSignedIn().then(isSignedIn => {

      let getGroupMembersObservable: Observable<any>;
      const getRequestUrl = `${this.config.serverUrl}groups/recommends/topContributors`;
      const params: GroupMembersRequestParams = {
        appId: this._appId,
        start,
        count,
      };

      if (isSignedIn) {
        getGroupMembersObservable = this.authService.secureGet(getRequestUrl, {
          params: toAuthRequestParams(params)
        });
      } else {
        getGroupMembersObservable = this.httpClient.get(getRequestUrl, {
          params: params as any
        });
      }

      return firstValueFrom(getGroupMembersObservable.pipe(
        this.schemaValidator.isValidOperator<GroupMembersResponse>('GroupMembersResponse')
      ));
    });
  }

  getMemberRequests(groupId: string,
    start: number = this.defaultStart,
    count: number = this.defaultGroupMembersCount,
    type?: string): Promise<GroupMembersResponse> {

    return this.authService.isSignedIn().then(isSignedIn => {
      let getMemberRequestsObservable: Observable<any>;
      const getRequestUrl = `${this.config.serverUrl}groups/${groupId}/memberRequests`;

      const params: GroupMembersRequestParams = {
        appId: this._appId,
        start,
        count,
        type
      };

      if (isSignedIn) {
        getMemberRequestsObservable = this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) });
      } else {
        getMemberRequestsObservable = this.httpClient.get(getRequestUrl, { params: params as any });
      }

      return firstValueFrom(getMemberRequestsObservable.pipe(
        this.schemaValidator.isValidOperator<GroupMembersResponse>('GroupMembersResponse')
      ));
    });
  }

  getPostByPostId(postId: string): Promise<PostByIdResponse> {
    let getGroupPostByIdObservable: Observable<any>;
    const getRequestUrl = `${this.config.serverUrl}posts/${postId}`;

    const params: GroupPostByIdRequestParams = {
      appId: this._appId
    };

    return this.authService.isSignedIn().then(isSignedIn => {

      if (isSignedIn) {
        getGroupPostByIdObservable = this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) });
      } else {
        getGroupPostByIdObservable = this.httpClient.get(getRequestUrl, { params: params as any });
      }

      return firstValueFrom(getGroupPostByIdObservable.pipe(
        this.schemaValidator.isValidOperator<PostByIdResponse>('PostByIdResponse')
      ));
    });
  }

  createGroup(groupData: SaveGroupData): Promise<CreateGroupResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const postRequestUrl = `${this.config.serverUrl}groups`;
      const options = {
        params: toAuthRequestParams({
          appId: this._appId
        })
      };

      return firstValueFrom(this.authService.securePost<SaveGroupData, CreateGroupResponse>(postRequestUrl, groupData, options).pipe(
        this.schemaValidator.isValidOperator<CreateGroupResponse>('CreateGroupResponse')
      ));
    });
  }

  updateGroup(groupData: SaveGroupData, groupId: string): Promise<UpdateGroupResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const putRequestUrl = `${this.config.serverUrl}groups/${groupId}`;
      const options = {
        params: toAuthRequestParams({
          appId: this._appId
        })
      };

      return firstValueFrom(this.authService.securePut<SaveGroupData, UpdateGroupResponse>(putRequestUrl, groupData, options).pipe(
        this.schemaValidator.isValidOperator<UpdateGroupResponse>('UpdateGroupResponse')
      ));
    })
  }

  async requestToJoinGroup(groupId: string, email: string, userid: string): Promise<RequestToJoinGroupResponse> {

    const userVerificationStatus = await this.verificationService.verify(true);

    if (userVerificationStatus !== VerificationStatus.VERIFIED && userVerificationStatus !== VerificationStatus.PENDING) {
      return Promise.reject('User is not verified');
    }

    const requestParams: RequestToJoinGroupRequestParams = {
      appId: this._appId
    }

    const requestData: RequestToJoinGroupRequest = { email, userid };

    const postRequestUrl = `${this.config.serverUrl}groups/${groupId}/memberRequests`;

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(
        this.authService.securePost<RequestToJoinGroupRequest, RequestToJoinGroupResponse>(postRequestUrl, requestData, {
          params: toAuthRequestParams(requestParams)
        }).pipe(
          this.schemaValidator.isValidOperator<RequestToJoinGroupResponse>('RequestToJoinGroupResponse')
        )
      );
    });
  }

  async joinGroup(groupId: string, email: string, userid: string): Promise<JoinGroupResponse> {

    const userVerificationStatus = await this.verificationService.verify(true);

    if (userVerificationStatus !== VerificationStatus.VERIFIED && userVerificationStatus !== VerificationStatus.PENDING) {
      return Promise.reject('User is not verified');
    }

    const requestParams: JoinGroupRequestParams = {
      appId: this._appId
    }

    const joinGroupMember: JoinGroupMember = {
      email,
      userId: userid
    };

    const requestData: JoinGroupRequest = {
      members: [joinGroupMember]
    }

    const postRequestUrl = `${this.config.serverUrl}groups/${groupId}/members`;

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(this.authService.securePut<JoinGroupRequest, JoinGroupResponse>(postRequestUrl, requestData, {
        params: toAuthRequestParams(requestParams)
      }).pipe(
        this.schemaValidator.isValidOperator<JoinGroupResponse>('JoinGroupResponse')
      ));
    });
  }

  updateGroupRequest(memberRequestId: string, status: string, userid: string, groupId: string): Promise<void> {
    const appId = this._appId;
    const postRequestUrl = `${this.config.serverUrl}groups/${groupId}/memberRequests?appId=${appId}`;
    const requestParams: AcceptRejectGroupInvitationRequest = {
      memberRequestId,
      status,
      userid,
    };

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(this.authService.securePut(postRequestUrl, requestParams).pipe(
        this.schemaValidator.isValidOperator<AcceptRejectGroupInvitationResponse>('AcceptRejectGroupInvitationResponse'),
      )).then(() => {/* void */ });
    });
  }

  async acceptRejectGroupInvitation(groupId: string, status: boolean, memberRequestId: string, userid: string):
    Promise<AcceptRejectGroupInvitationResponse> {

    return this.authService.asserIsSignedIn().then(async () => {

      if (status) {
        const userVerificationStatus = await this.verificationService.verify(true);

        if (userVerificationStatus !== VerificationStatus.VERIFIED && userVerificationStatus !== VerificationStatus.PENDING) {
          return Promise.reject();
        }
      }

      const requestParams: AcceptRejectGroupInvitationRequestParams = {
        appId: this._appId
      }

      const requestData: AcceptRejectGroupInvitationRequest = {
        userid,
        memberRequestId,
        status: status ? 'Accepted' : 'Decline'
      }

      const postRequestUrl = `${this.config.serverUrl}groups/${groupId}/memberInvitations`;

      return firstValueFrom(
        this.authService.securePut<AcceptRejectGroupInvitationRequest, AcceptRejectGroupInvitationResponse>(
          postRequestUrl,
          requestData,
          { params: toAuthRequestParams(requestParams) }
        ).pipe(
          this.schemaValidator.isValidOperator<AcceptRejectGroupInvitationResponse>('AcceptRejectGroupInvitationResponse')
        )
      );
    });
  }

  updateGroupMember(userId: string, role: string, groupId: string, status?: string,): Promise<UpdateGroupMemberResponse> {
    return this.authService.asserIsSignedIn().then(() => {
      const appId = this._appId;
      const postRequestUrl = `${this.config.serverUrl}groups/${groupId}/members?appId=${appId}`;
      const members = [{ userId, role, status }];
      const requestParams = { members };

      return firstValueFrom(this.authService.securePut(postRequestUrl, requestParams).pipe(
        this.schemaValidator.isValidOperator<UpdateGroupMemberResponse>('UpdateGroupMemberResponse')
      ));
    });
  }

  searchForGroupParticipant(
    groupId: string,
    searchTerm: string,
    start: number = this.defaultStart,
    count: number = this.defaultSearchGroupParticipantsCount,
    excludedUserIds?: Array<string>): Promise<SearchGroupParticipantsResponse> {
    return this.authService.asserIsSignedIn().then(() => {
      const appId = this._appId;
      const getRequestUrl = `${this.config.serverUrl}groups/participants/search/${groupId}`;
      const requestParams = {
        appId,
        query: searchTerm,
        start,
        count,
        excludedUserIds: excludedUserIds || []
      };

      return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(requestParams) }).pipe(
        this.schemaValidator.isValidOperator<SearchGroupParticipantsResponse>('SearchGroupParticipantsResponse')
      ));
    });
  }

  createPost(data: CreatePostRequest): Promise<CreatePostResponse> {
    return this.authService.asserIsSignedIn().then(async () => {

      const appId = this._appId;
      const postRequestUrl = `${this.config.serverUrl}posts/v2`;
      const requestParams: CreatePostRequestParams = {
        appId: appId
      };
      return firstValueFrom(this.authService.securePost<CreatePostRequest, CreatePostResponse>(postRequestUrl, data,
        { params: toAuthRequestParams(requestParams) }).pipe(
          this.schemaValidator.isValidOperator<CreatePostResponse>('CreatePostResponse')
        ));
    });
  }

  shareObjectInMultipleGroupsAsPost(data: ShareObjectInMultipleGroupsEventsRequestData)
    : Promise<ShareObjectInMultipleGroupsEventsResponse> {

    return this.authService.asserIsSignedIn().then(async () => {

      const appId = this._appId;
      const postRequestUrl = `${this.config.serverUrl}groups/share`;
      const requestParams: ShareObjectInMultipleGroupsRequestParams = {
        appId: appId
      };
      return firstValueFrom(this.authService.securePost<ShareObjectInMultipleGroupsEventsRequestData,
        ShareObjectInMultipleGroupsEventsResponse>
        (postRequestUrl, data,
          { params: toAuthRequestParams(requestParams) }).pipe(
            this.schemaValidator.isValidOperator<ShareObjectInMultipleGroupsEventsResponse>('ShareObjectInMultipleGroupsEventsResponse')
          ));
    });
  }

  getPostsV2(parentId: string, parentType: ParentType, facultyOnly: boolean = false, start: number = this.defaultStart,
    count: number = this.defaultPostsCount): Promise<PostsListResponse> {

    return this.authService.asserIsSignedIn().then(() => {
      const getRequestUrl = `${this.config.serverUrl}posts/v2/${parentType}/${parentId}`;

      const params: PostsListRequestParams = {
        appId: this._appId,
        start,
        count
      };

      if (facultyOnly) {
        params.facultyOnly = facultyOnly;
      }

      return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
        this.schemaValidator.isValidOperator<PostsListResponse>('PostsListResponse')
      ));
    });
  }

  updatePost(postId: string, data: UpdatePostRequest): Promise<UpdatePostResponse> {
    return this.authService.asserIsSignedIn().then(() => {

      const appId = this._appId;
      const postRequestUrl = `${this.config.serverUrl}posts/v2/${postId}`;
      const requestParams: UpdatePostRequestParams = {
        appId: appId
      };

      return firstValueFrom(this.authService.securePut<UpdatePostRequest, UpdatePostResponse>(postRequestUrl, data,
        { params: toAuthRequestParams(requestParams) }).pipe(
          this.schemaValidator.isValidOperator<UpdatePostResponse>('UpdatePostResponse')
        ));
    });
  }

  deletePost(postId: string): Promise<DeletePostResponse> {
    return this.authService.asserIsSignedIn().then(() => {

      const appId = this._appId;
      const postRequestUrl = `${this.config.serverUrl}posts/${postId}`;
      const requestParams: DeletePostRequestParams = {
        appId: appId
      };

      return firstValueFrom(this.authService.secureDelete<any, DeletePostResponse>(postRequestUrl, null,
        { params: toAuthRequestParams(requestParams) }).pipe(
          this.schemaValidator.isValidOperator<DeletePostResponse>('DeletePostResponse')
        ));
    });
  }

  likeOrUnlikeObject(
    objectId: string,
    objectType: 'post' | 'case' | 'comment' | 'orcid',
    value: boolean,
    ownerId?: string
  ): Promise<LikeUnlikeResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const appId = this._appId;
      const postRequestUrl = `${this.config.serverUrl}likes/${objectType}/${objectId}`;

      const data: LikeUnlikeRequest = {value, ownerId};

      const requestParams: LikeUnlikeRequestParams = {
        appId: appId
      };

      return firstValueFrom(this.authService.securePut<LikeUnlikeRequest, LikeUnlikeResponse>(postRequestUrl, data,
        { params: toAuthRequestParams(requestParams) }));
    });
  }

  getLikesBasedOnObjectType(
    objectId: string,
    objectType: 'post' | 'case' | 'comment' | 'orcid' | 'article',
    start: number = this.defaultStart,
    count: number = this.defaultGroupListCount
  ): Promise<GetLikesResponse> {
    return this.authService.asserIsSignedIn().then(() => {
      const getRequestUrl = `${this.config.serverUrl}likes/${objectType}/${objectId}`;

      const requestParams: PaginationContextRequestParams = {
        appId: this._appId,
        start,
        count,
      }

      return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(requestParams) }).pipe(
        this.schemaValidator.isValidOperator<GetLikesResponse>('GetPostLikesResponse')
      ));
    });
  }

  // TODO: to be integrated when we show people that recommended for a group (not used for now)
  getGroupRecommends(groupId: string, start: number = this.defaultStart, count: number = this.defaultGroupListCount):
    Promise<GetGroupRecommendsResponse> {
    return this.authService.asserIsSignedIn().then(() => {

      const getRequestUrl = `${this.config.serverUrl}groups/recommends/${groupId}`;

      const requestParams: PaginationContextRequestParams = {
        appId: this._appId,
        start,
        count
      }
      return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(requestParams) }).pipe(
        this.schemaValidator.isValidOperator<GetGroupRecommendsResponse>('GetGroupRecommendsResponse')
      ));
    });
  }

  inviteToGroup(groupId: string, email?: string, id?: string, aoSearch?: boolean): Promise<RequestToJoinGroupResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const appId = this._appId;
      const postRequestUrl = `${this.config.serverUrl}groups/${groupId}/memberInvitations?appId=${appId}`;
      const requestParams = {
        email,
        id,
        aoSearch
      };

      return firstValueFrom(this.authService.securePost(postRequestUrl, requestParams).pipe(
        this.schemaValidator.isValidOperator<RequestToJoinGroupResponse>('RequestToJoinGroupResponse')
      ));
    });
  }

  sendReminderForGroupInvitation(groupId: string, email: string): Promise<SendReminderForGroupInvitationResponse> {
    return this.authService.asserIsSignedIn().then(() => {
      const appId = this._appId;
      const postRequestUrl = `${this.config.serverUrl}groups/${groupId}/memberInvitations/sendReminder?appId=${appId}`;
      const requestParams = {
        email,
      };

      return firstValueFrom(this.authService.securePost(postRequestUrl, requestParams));
    });
  }

  removeGroupPostImage(
    postId: string,
    imageId: string,
    fileType?: CaseFileType,
    postType?: PostType): Promise<RemovePostAttachmentResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const appId = this._appId;
      const postRequestUrl = `${this.config.serverUrl}posts/${postId}/${imageId}`;
      const requestParams: RemovePostAttachmentRequestParams = {
        appId: appId
      };

      if (fileType) {
        requestParams.fileType = fileType;
      }

      if (postType) {
        requestParams.postType = postType;
      }

      return firstValueFrom(this.authService.secureDelete<any, RemovePostAttachmentResponse>(postRequestUrl, null,
        { params: toAuthRequestParams(requestParams) }).pipe(
          this.schemaValidator.isValidOperator<RemovePostAttachmentResponse>('RemovePostAttachmentResponse')
        ));
    });
  }

  reportPostAsAbuse(postId: string, reason?: string): Promise<ReportAsAbuseResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const postRequestUrl = `${this.config.serverUrl}groups/report`;

      const data: ReportAsAbuseRequest = {
        objectType: ReportAsAbuseObjectType.POST,
        objectId: postId,
        reason: reason || ''
      };

      const appId = this._appId;
      const requestParams: ReportAsAbuseRequestParams = { appId };
      const options = { params: toAuthRequestParams(requestParams) };

      return firstValueFrom(this.authService.securePost<ReportAsAbuseRequest, ReportAsAbuseResponse>(postRequestUrl, data,
        options).pipe(
          this.schemaValidator.isValidOperator<ReportAsAbuseResponse>('ReportAsAbuseResponse')
        ));
    });
  }

  reportGroup(groupId: string, reason: string): Promise<ReportGroupResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const postRequestUrl = `${this.config.serverUrl}groups/report`;

      const data: ReportAsAbuseRequest = {
        objectType: ReportAsAbuseObjectType.GROUP,
        objectId: groupId,
        reason: reason || ''
      };
      const appId = this._appId;
      const options = {
        params: toAuthRequestParams({
          appId
        })
      };

      return firstValueFrom(this.authService.securePost<ReportAsAbuseRequest, ReportGroupResponse>(postRequestUrl, data, options)
        .pipe(
          this.schemaValidator.isValidOperator<ReportGroupResponse>('ReportGroupResponse')
        ));
    });
  }

  reportCommentAsAbuse(commentId: string, reason?: string): Promise<ReportAsAbuseResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const data: ReportAsAbuseRequest = {
        objectType: ReportAsAbuseObjectType.COMMENT,
        objectId: commentId,
        reason: reason || ''
      };

      const appId = this._appId;
      const options = { params: toAuthRequestParams({ appId }) };
      const postRequestUrl = `${this.config.serverUrl}groups/report`;

      return firstValueFrom(this.authService.securePost<ReportAsAbuseRequest, ReportAsAbuseResponse>(postRequestUrl, data, options)
        .pipe(
          this.schemaValidator.isValidOperator<ReportAsAbuseResponse>('ReportAsAbuseResponse')
        ));
    });
  }

  getCaseClassifications(language?: string): Promise<CaseClassificationsResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const appId = this._appId;
      const requestParams: CaseClassificationsRequestParams = {
        appId: appId,
        language: language || ''
      };
      const options = {
        params: toAuthRequestParams(requestParams)
      };
      const postRequestUrl = `${this.config.serverUrl}posts/caseClassifications`;

      return firstValueFrom(this.authService.secureGet<CaseClassificationsResponse>(postRequestUrl, options).pipe(
        this.schemaValidator.isValidOperator<CaseClassificationsResponse>('CaseClassificationsResponse')
      ));
    });
  }

  pinPost(postId: string): Promise<PinPostResponse> {
    return this.authService.asserIsSignedIn().then(async () => {

      const appId = this._appId;
      const pinPostUrl = `${this.config.serverUrl}posts/v2/${postId}/pin`;
      const requestParams: PinPostRequestParams = {
        appId: appId
      };
      return firstValueFrom(this.authService.securePut<null, PinPostResponse>(pinPostUrl, null,
        { params: toAuthRequestParams(requestParams) }).pipe(
          this.schemaValidator.isValidOperator<PinPostResponse>('PinPostResponse')
        ));
    });
  }

  unpinPost(postId: string): Promise<UnpinPostResponse> {
    return this.authService.asserIsSignedIn().then(async () => {

      const appId = this._appId;
      const unpinPostUrl = `${this.config.serverUrl}posts/v2/${postId}/unpin`;
      const requestParams: UnpinPostRequestParams = {
        appId: appId
      };
      return firstValueFrom(this.authService.securePut<null, UnpinPostResponse>(unpinPostUrl, null,
        { params: toAuthRequestParams(requestParams) }).pipe(
          this.schemaValidator.isValidOperator<UnpinPostResponse>('UnpinPostResponse')
        ));
    });
  }

  getGroupsStream(start: number = this.defaultStart, count: number = DEFAULT_GROUPS_FEED_COUNT): Promise<GroupsStreamResponse> {

    return this.authService.asserIsSignedIn().then(() => {
      const getRequestUrl = `${this.config.serverUrl}groups/stream`;

      const params: PostsListRequestParams = {
        appId: this._appId,
        start,
        count
      };

      return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
        this.schemaValidator.isValidOperator<GroupsStreamResponse>('GroupsStreamResponse')
      ));
    });
  }

  getUserRecentPublicContributions(start: number,
    count: number,
    userId?: string,
    filter?: UserRecentPublicContributionsFilter): Promise<UserRecentPublicContributionsResponse> {
    return this.authService.asserIsSignedIn().then(() => {

      let getRequestUrl = '';

      if (userId) {
        getRequestUrl = `${this.config.serverUrl}posts/v2/publicContributions/${userId}`;
      } else {
        getRequestUrl = `${this.config.serverUrl}posts/v2/publicContributions`;
      }

      const params: UserRecentPublicContributionsParams = {
        appId: this._appId,
        start,
        count
      };

      if (filter) {
        params.filterBy = filter;
      }

      return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
        this.schemaValidator.isValidOperator<UserRecentPublicContributionsResponse>('UserRecentPublicContributionsResponse')
      ));
    });
  }

  private fetchGroups(params: GroupsListRequestParams, _fetchCongressGroups?: boolean): Promise<GroupsListResponse> {

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(this.authService.secureGet(`${this.config.serverUrl}groups`, { params: toAuthRequestParams(params) }).pipe(
        this.schemaValidator.isValidOperator<GroupsListResponse>('GroupsListResponse')
      ));
    });
  }

  get _appId(): string {
    return this.contextService.currentContext.key;
  }

  parseServerErrorResponse(errorResponse: ErrorResponse): ParsedServerError {
    let errorInfo;
    const errorJson = errorResponse?.error?.info || (errorResponse as any)?.error?.error?.info;

    try {
      errorInfo = JSON.parse(errorJson.replace('/', ''));
      console.log(errorInfo);
      return {
        errorMessage: errorInfo?.message,
        groupId: errorInfo?.groupId,
        errorCode: errorInfo?.errorCode
      };
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  hasAccessToPost(errorCode: string): boolean {
    switch (errorCode) {
      case 'noAccessForNonParticipants':
      case 'noAccessForNonVerified':
      case 'noAccessForNonAOMembers':
      case 'viwOrPostNotAllowed':
        return false;
      default:
        return true;
    }
  }
}
