import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import appConfig from 'src/config/config';

// services
import { AuthService } from '../../services/auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { ContextSwitchService, CONTEXT_SWITCH_SERVICE } from 'src/app/services/context/context-switch.model';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';

// models
import { ContextConfigInterface } from '../../../config/config.model';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';

// components
import { ContextAddUIService } from 'src/app/services/context/context-add-ui.service';
import { Store } from '@ngxs/store';
import { ActiveConversations } from 'src/app/state/active-conversations/active-conversations.actions';
import { Activities } from 'src/app/state/activities/activities.actions';
import { ClinicalCasesAccessRequests } from 'src/app/state/clinical-cases-access-requests/clinical-cases-access-requests.actions';

export enum ObjectId {
  SWITCH = 'contextSwitch',
  ADD = 'contextAdd'
}

@Component({
  selector: 'app-context-switch',
  templateUrl: './context-switch.component.html',
  styleUrls: ['./context-switch.component.scss'],
})
export class ContextSwitchComponent implements OnInit, OnDestroy {

  contexts: Array<ContextConfigInterface> = [];
  mainContext: ContextConfigInterface = null;
  addedContexts: Array<ContextConfigInterface> = [];
  availableForAddingContexts: Array<ContextConfigInterface> = [];
  selectedContextKey: string;
  userContextsChangesSubscription: Subscription;
  protected userProfile: UserProfile;
  private routeSubscription: Subscription;
  private userProfileSubscription: Subscription;

  constructor(
    private router: Router,
    @Inject(CONTEXT_SWITCH_SERVICE) private contextSwitchService: ContextSwitchService,
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private contextAddUIService: ContextAddUIService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.contexts = appConfig.contexts;

    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.selectedContextKey = this.contextService.currentContext.key;
      }
    });

    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: any) => {
      this.initContextsSettings(userProfile);
    });

    this.userContextsChangesSubscription = this.contextSwitchService.userContextsUpdated.subscribe(() => {
      this.authService.getProfile(this.selectedContextKey)
        .then(userProfile => {
          this.initContextsSettings(userProfile);
        })
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    this.userProfileSubscription?.unsubscribe();
    this.userContextsChangesSubscription?.unsubscribe();
  }

  onChangeContext(context: ContextConfigInterface): void {
    this.contextSwitchService.contextHasInterests(context.key)
      .then(hasInterests => {
        if (hasInterests) {
          return true;
        }
        return this.contextAddUIService.addContext(
          context,
          this.availableForAddingContexts,
          true
        );
      })
      .then(hasInterests => {
        if (hasInterests) {
          this.selectedContextKey = context.key;

          if (context) {
            return this.authService.reloadProfile(context.key)
              .then(() => {
                const config = this.router.config;

                const i = config.findIndex(x => !x.outlet && x.path === context.key);
                config.splice(i, 1);
                const route = this.contextSwitchService.getContextRootRoutes(context.key)[0];
                config.push(route);

                this.contextSwitchService.upcomingContext = context;
                this.router.navigateByUrl(context.homePath, { replaceUrl: true });
                this.contextSwitchService.closeContextSwitchMenu();

                setTimeout(() => {
                  this._initIndicators();
                }, 500);
              });
          }
        }
      });
    this.trackSwitchCurrentContextClicked(ObjectId.SWITCH);
  }

  onAddContext(context: ContextConfigInterface): void {
    this.contextAddUIService.addContext(context, this.availableForAddingContexts, true)
      .then(contextAdded => {
        if (contextAdded) {
          this.onChangeContext(context);
          this.initContextsSettings(this.userProfile);
        }
      });
    this.trackSwitchCurrentContextClicked(ObjectId.ADD);
  }

  trackSwitchCurrentContextClicked(objectId: ObjectId): Promise<void> {
    const objId = objectId;
    const objType = 'context';
    const objectTitle = 'switchCurrentContext';

    return this.trackingService.trackGenericClickedAction(objId, objType, objectTitle)
      .catch(_err => {
        console.error('Could not track switchCurrentContext click action.');
      });
  }

  private async initContextsSettings(userProfile: UserProfile): Promise<void> {

    this.userProfile = userProfile;

    if (!userProfile) {
      this.mainContext = null;
      this.addedContexts = [];
      this.availableForAddingContexts = [];
      return;
    }

    const contexts = await this.contextSwitchService.getUserContexts(userProfile);

    this.mainContext = contexts && contexts.mainContext ? contexts.mainContext : null;
    this.addedContexts = contexts && contexts.addedContexts ? contexts.addedContexts : [];
    this.availableForAddingContexts = contexts && contexts.availableForAddingContexts ? contexts.availableForAddingContexts : [];
  }

  _initIndicators(): void {
    // to update UI indicators
    this.store.dispatch(new ActiveConversations.FetchActiveConversations({ pageIndex: 0 }));
    this.store.dispatch(new Activities.FetchActivities({ pageIndex: 0 }));
    this.store.dispatch(new ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequests({ pageIndex: 0 }));
  }
}
