import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { CaseLibraryFilterDialogComponent } from 'src/app/dialogs/case-library-filter-dialog/case-library-filter-dialog.component';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from 'src/app/dialogs/error-dialog/error-dialog.component';
import { QrCodeDialogComponent } from 'src/app/dialogs/qr-code-dialog/qr-code-dialog.component';
import { AppTranslationService } from '../app-translation.service';
import { ConfirmDialogData, FieldMaxTextLengthExceeded } from './dialogs.ui.interface';
import {EditTextDialogComponent} from 'src/app/dialogs/edit-text-dialog/edit-text-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogsUIService {
  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private appTranslation: AppTranslationService
  ) { }

  /**
   * @desc Show error dialog
   * @param errorMessage - error message to display
   */
  showErrorDialog(errorMessage: string): void {
    this.modalController.create({
      component: ErrorDialogComponent,
      componentProps: {
        errorMessage
      },
      cssClass: 'error-dialog-modal'
    }).then(modalEl => {
      modalEl.present();
    });
  }

  showErrorDialogById(errorMessageId: string): void {
    this.appTranslation.get(errorMessageId)
      .then(errorMessage => {
        this.showErrorDialog(errorMessage);
      });
  }

  createConfirmDialog(confirmDialogData: ConfirmDialogData,
    onlyTitleDialog?: boolean, backdropDismiss: boolean = false): Promise<HTMLIonModalElement> {
    return this.modalController.create({
      component: ConfirmDialogComponent,
      componentProps: confirmDialogData.componentProps,
      cssClass: onlyTitleDialog ? 'small-confirm-dialog' : 'generic-confirm-dialog',
      backdropDismiss: backdropDismiss,
      showBackdrop: true,
      animated: false
    });
  }

  showConfirmCommunityGuidelines(): Promise<boolean> {

    const ACTION_KEY = 'post';
    const BACK_KEY = 'goBack';

    const confirmCommunityGuideLinesData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.dialogs.confirm.community-guidelines-title'
        },
        message: {
          translationKey: 'app.dialogs.confirm.community-guidelines-message'
        },
        actions: [
          {
            key: BACK_KEY,
            label: {
              translationKey: 'app.common.go-back'
            },
            className: 'secondary'
          },
          {
            key: ACTION_KEY,
            label: {
              translationKey: 'app.common.confirm'
            },
            className: 'primary'
          }
        ]

      }
    }

    return this.createConfirmDialog(confirmCommunityGuideLinesData)
      .then(dlg => {
        dlg.present();
        return dlg.onDidDismiss();
      })
      .then(res => {
        if (res && res?.data.actionKey === ACTION_KEY) {
          return true;
        }
      });
  }

  async showNonParticipantsConfirmDialog(): Promise<boolean> {
    const joinAndPostActionKey = 'join-post';
    const discardActionKey = 'discard';

    const confirmNonParticipantsData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.components.PostTypeCase.non-participant-dialog-title'
        },
        message: {
          translationKey: 'app.components.PostTypeCase.non-participant-dialog-text'
        },
        actions: [
          {
            key: joinAndPostActionKey,
            label: {
              translationKey: 'app.components.PostTypeCase.non-participant-dialog-confirm-btn'
            },
            className: 'primary'
          },
          {
            key: discardActionKey,
            label: {
              translationKey: 'app.components.PostTypeCase.non-participant-dialog-cancel-btn'
            },
            className: 'secondary'
          }
        ]
      }
    }

    return this.createConfirmDialog(confirmNonParticipantsData)
      .then(dlg => {
        dlg.present();
        return dlg.onDidDismiss();
      })
      .then(res => {
        if (res && res?.data.actionKey === joinAndPostActionKey) {
          return true;
        } else {
          return false;
        }
      });
  }

  async showFieldsMaxTextLengthExceeded(
    fieldsMaxTextLengthExceeded: Array<FieldMaxTextLengthExceeded>): Promise<void> {

    const messagesString = [];

    for (const field of fieldsMaxTextLengthExceeded) {
      const messagePartOne = await this.appTranslation.get('app.common.text-field-max-length-exceeded-part-1');
      const messagePartTwo = await this.appTranslation.get('app.common.text-field-max-length-exceeded-part-2');
      const messagePartThree = await this.appTranslation.get('app.common.text-field-max-length-exceeded-part-3');

      messagesString.push(`${messagePartOne} ${field.fieldName} ${messagePartTwo} ${field.maxTextLength} ${messagePartThree}`);
    }

    const verificationPendingConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          text: 'Oops...'
        },
        message: {
          text: messagesString.join('\n\n')
        },
        actions: [
          {
            key: 'got-it',
            label: {
              translationKey: 'app.common.got-it',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.createConfirmDialog(verificationPendingConfirmDialogData);
    confirmModal.present();
  }

  showQRCodeDialog(): void {
    this.modalController.create({
      component: QrCodeDialogComponent,
      cssClass: 'qr-code-dialog'
    }).then(modalEl => {
      modalEl.present();
    });
  }

  async presentAlert(title: string, text: string, buttonsText: Array<string>): Promise<any> {
    const alert = await this.alertController.create({
      header: title,
      message: text,
      buttons: buttonsText
    });

    await alert.present();
    return alert.onDidDismiss();
  }

  async showCaseLibraryFilterDialog(filters: Array<string>, selectedFilter?: string): Promise<string> {

    const dialog = await this.modalController.create({
      component: CaseLibraryFilterDialogComponent,
      cssClass: 'case-library-filter-dialog',
      componentProps: {
        filters: filters,
        selectedFilter: selectedFilter
      },
      backdropDismiss: false
    });

    dialog.present();

    try {
      const res = await dialog.onDidDismiss();

      if (res?.data) {
        return res.data;
      }

      return '';
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  showEditTextDialog(
    resourceId: string,
    text: string,
    textLimit: number,
    preventSaveWhenEmpty?: boolean,
    simpleTextArea?: boolean): Promise<string | null | void> {
    return new Promise(resolve => {

      return this.modalController.create({
        component: EditTextDialogComponent,
        componentProps: {
          headerResourceId: resourceId,
          text: text || '',
          textLimit: textLimit,
          preventSaveWhenEmpty: preventSaveWhenEmpty,
          simpleTextArea: simpleTextArea
        },
        cssClass: 'rounded-modal small-modal'
      }).then(modalEl => {
        modalEl.onWillDismiss()
          .then(res => {
            if (res.role === 'cancel') {
              return resolve();
            }
            if (res.role === 'save') {
              return resolve(res.data);
            }
            resolve();
          });
        return modalEl.present();
      });
    });
  }
}
