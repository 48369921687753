import { PackageJsonConfig } from './config.model';

export const packageConfig: PackageJsonConfig = {
  oneSignal: {
    appId: '',
    firebaseProjectNumber: '96985924353',
    triggerKey: 'prompt_ios',
    triggerValue: 'true'
  },
  schemaValidationEnabled: true,
  feedbackUrl: 'https://myaofeedback.featureupvote.com',
  aiSurgeryReferenceApiKey: 'in5ights-api-key'
}
