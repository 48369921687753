import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import {
  CaseClassificationFields
} from 'src/app/components/case-classification-tags-selection/case-classification-tags-selection.model';
import { EditTextDialogComponent } from 'src/app/dialogs/edit-text-dialog/edit-text-dialog.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { ClinicalCaseUIService } from 'src/app/services/case-library/clinical-case.ui.service';
import { NavControllerService } from 'src/app/services/nav-controller.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import {
  UI_UTILS_SERVICE,
  UIUtilsServiceInterface,
  GroupedImagesByStageOfTreatment
} from 'src/app/services/utils/ui-utils.service.interface';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import {
  ClinicalCase,
  ClinicalCaseUpdateData,
  ExportClinicalCaseResponse,
  ExportClinicalCaseSuccessResponse,
  GetSharedWithSuccessResponse,
  SharedWith,
  SharedWithType
} from 'src/app/services/yeti-protocol/clinical-case';
import { ActionSource, ActionTracked, GenericTrackingParam, TrackingRequest } from 'src/app/services/yeti-protocol/tracking';
import { PersonalMediaGalleryDocument } from 'src/app/services/yeti-protocol/personal-media-gallery';
import { ImageAttachment, isAnyImage, isVideo } from 'src/app/services/attachments.model';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { stripHtmlTagsFromString } from 'src/app/services/utils/string-utils';
import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';

// mocks
import { PublicProfile } from 'src/app/services/yeti-protocol/public-profile';

// services
import { PublicProfileService } from 'src/app/services/public-profile.service';
import { ImageGalleryService } from 'src/app/modules/file-select/services/image-gallery.service';
import { ActionContent, GalleryConfig, ImageGalleryActions } from 'src/app/modules/file-select/services/image-gallery.model';
import { EditCaseImagesDialogRes } from 'src/app/services/utils/ui-utils.model';
import { TutorialForFeature, TutorialService } from 'src/app/services/tutorial.service';
import { ConfirmDialogData, ContextDialogsUI, CONTEXT_DIALOGS_UI } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import {
  CaseReadinessIndicatorState,
  CaseReadinessStageState
} from '../../components/case-readiness-indicator/case-readiness-indicator.component';
import { CreateEditCasePostBottomBarConfig }
  from 'src/app/components/create-edit-case-post-bottom-bar/create-edit-case-post-bottom-bar.component';
import { CaseDocumentsUploadEditPreviewComponent }
  from 'src/app/components/case-documents-upload-edit-preview/case-documents-upload-edit-preview.component';
import { ActionOnInit } from 'src/app/components/case-image-upload-edit/case-image-upload-edit.model';
import { FileSelectScope } from 'src/app/modules/file-select/services/file-select.service';
import {
  SurgeryReferenceDialogPage,
  SURGERY_REFERENCE_UI_SERVICE
} from 'src/app/services/surgery-reference/surgery-reference-ui.service.interface';
import { SurgeryReferenceUIService } from 'src/app/services/surgery-reference/surgery-reference-ui.service';
import { SurgeryReferenceDialogData } from 'src/app/dialogs/surgery-reference/surgery-reference-dialog/surgery-reference-dialog.component';
import { InfoSheetService } from '../../modules/info-sheet/services/info-sheet.service';
import { SURGERY_REFERENCE_SERVICE } from 'src/app/services/surgery-reference/surgery-reference.service.interface';
import { SurgeryReferenceService } from 'src/app/services/surgery-reference/surgery-reference.service';
import {
  Approach,
  ContentType,
  Fracture,
  FractureAndTreatment,
  Preparation,
  Treatment
} from 'src/app/services/yeti-protocol/surgery-reference-schema';
import { FracturesSuccessResponse } from 'src/app/services/yeti-protocol/surgery-reference';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageGalleryCaseStrategy } from 'src/app/services/image-gallery/image-gallery-case-service.service';
import {
  PersonalMediaGalleryDocumentWithStageOfTreatment
} from 'src/app/components/stage-of-treatment/stage-of-treatment.model';
import { StageOfTreatmentId } from 'src/app/components/stage-of-treatment/stage-of-treatment.model';
import { IconLabelPosition } from 'src/app/modules/buttons/base-button/base-button.component';
import { getImagesWithCorrectUrlBasedOnWatermark } from '../../services/utils/image-attachments.utils';
import {LikeUnlikeSuccessResponse} from '../../services/yeti-protocol/chatter-api';

export enum TextLimit {
  galery_desc = 200,
  text_desc = 500,
  text_title = 90
}

const TUTORIAL_KEY = TutorialForFeature.CASE_FOLIO;

@Component({
  selector: 'app-case',
  templateUrl: './case.page.html',
  styleUrls: ['./case.page.scss'],
})
export class CasePage implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(IonContent) content: IonContent;
  @ViewChild('documentsUploadPreview') documentsUploadEditPreviewComponent: CaseDocumentsUploadEditPreviewComponent;
  @ViewChild('documentsContainer') documentsContainer: ElementRef;
  @ViewChild('surgeryReferenceContainer') surgeryReferenceContainer: ElementRef;

  caseId: string;
  clinicalCase: ClinicalCase;
  profile: UserProfile;
  infoSheetId = 'case-page-infosheet';
  addDetailsInfoSheetId = 'case-page-add-details-infosheet';
  isLoadingFailed = false;
  TextLimit = TextLimit;
  caseOwnerProfile: PublicProfile;
  clinicalCaseSharedWithSuccessResponse: GetSharedWithSuccessResponse;
  clinicalCaseSharedWith: Array<SharedWith> = [];
  updatingClinicalCaseDocuments = false;
  caseReadinessIndicatorState: CaseReadinessIndicatorState = {
    stage1: CaseReadinessStageState.STATE_1,
    stage2: CaseReadinessStageState.STATE_1,
    stage3: CaseReadinessStageState.STATE_1
  }
  createEditCasePostBottomBarConfig: CreateEditCasePostBottomBarConfig;
  imagesSectionBottomBar: CreateEditCasePostBottomBarConfig;
  ActionOnInit = ActionOnInit;
  ActionSource = ActionSource;
  performingImageGalleryAction: boolean;
  StageOfTreatmentId = StageOfTreatmentId;
  IconLabelPosition = IconLabelPosition;
  caseFeatureStatusChanging: boolean;
  applaudStatusChanging: boolean;
  clinicalCaseLoading: boolean;

  private userSubscription: Subscription;
  private clinicalCaseUpdatedSubscription: Subscription;
  private imagesUpdatedSubscription: Subscription;
  private addSurgeryReferenceDialogClosedSubscription: Subscription;
  private addSurgeryReference: boolean;
  private removingModule: boolean;
  private prevSurgeryReferenceDialogData: SurgeryReferenceDialogData;
  private imageGalleryActionSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private clinicalCaseService: ClinicalCaseService,
    private clinicalCaseUIService: ClinicalCaseUIService,
    private navController: NavControllerService,
    private appNavController: AppNavController,
    private modalController: ModalController,
    private toast: ToastService,
    private imageGalleryService: ImageGalleryService,
    private publicProfileService: PublicProfileService,
    private dialogs: DialogsUIService,
    @Inject(CONTEXT_DIALOGS_UI) private contextDialogs: ContextDialogsUI,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private sharingUIService: SharingUIService,
    private appTranslationService: AppTranslationService,
    private tutorialService: TutorialService,
    @Inject(SURGERY_REFERENCE_UI_SERVICE) private surgeryReferenceUIService: SurgeryReferenceUIService,
    private router: Router,
    private infoSheetService: InfoSheetService,
    @Inject(SURGERY_REFERENCE_SERVICE) private surgeryReferenceService: SurgeryReferenceService,
    private sanitizer: DomSanitizer,
    private imageGalleryCaseStrategy: ImageGalleryCaseStrategy
  ) {
    this.addSurgeryReference = this.router.getCurrentNavigation()?.extras?.state?.addSurgeryReference;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.caseId = params.caseId;
      this.reloadClinicalCase();
      this.trackActionCaseOpened(params.caseId);
    });

    this.userSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.profile = userProfile;
    });

    this.clinicalCaseUpdatedSubscription = this.clinicalCaseService.clinicalCaseUpdatedObservable
      .subscribe(clinicalCase => this.onClinicalCaseUpdated(clinicalCase));

    this.checkAndShowTutorialFinishDialog();

    this.imagesUpdatedSubscription = this.imageGalleryService.imageUpdated.subscribe(image => {
      this._updateImage(image);
    });

    this.createEditCasePostBottomBarConfig = {
      images: {
        avaliable: () => { return true },
      },
      videos: {
        avaliable: () => { return true },
      },
      documents: {
        avaliable: () => { return false },
      },
      reorder: {
        avaliable: () => { return true },
        disabled: () => { return false },
        withBadge: () => { return true }
      },
      watermark: {
        avaliable: () => { return false }
      },
      settings: {
        avaliable: (): boolean => { return true }
      },
    }

    this.imagesSectionBottomBar = {
      images: {
        avaliable: () => { return true },
      },
      videos: {
        avaliable: () => { return true },
      },
      documents: {
        avaliable: () => { return false },
      },
      watermark: {
        avaliable: () => { return false }
      },
      reorder: {
        avaliable: () => { return true },
        disabled: () => { return false },
        withBadge: () => { return false }
      },
      settings: {
        avaliable: (): boolean => { return false }
      },
    }

    this.addSurgeryReferenceDialogClosedSubscription = this.surgeryReferenceUIService.dialogClosedObservable.subscribe(() => {
      setTimeout(() => {
        this.scrollToSurgeryReferenceModules();
      }, 100);
    });

    this.imageGalleryActionSubscription = this.imageGalleryService?.imageActionStream?.subscribe((action: ActionContent) =>
      this.executeImageGalleryAction(action));
  }

  ngAfterViewInit(): void {
    this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);
    this.addDetailsInfoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.addDetailsInfoSheetId);
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.clinicalCaseUpdatedSubscription?.unsubscribe();
    this.imagesUpdatedSubscription?.unsubscribe();
    this.addSurgeryReferenceDialogClosedSubscription?.unsubscribe();
    this.imageGalleryActionSubscription?.unsubscribe();
  }

  checkAndShowAddSurgeryReferenceDialog(): void {
    if (this.addSurgeryReference && this.clinicalCase?.fractures?.length === 0) {

      this.addSurgeryReference = null;

      this.surgeryReferenceService.getFractures(this.clinicalCase?._id).then((fracturesResponse: FracturesSuccessResponse) => {
        if (fracturesResponse?.result?.length) {
          this.openSurgeryReferenceDialog();
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }

  async openSurgeryReferenceDialog(): Promise<void> {
    this.prevSurgeryReferenceDialogData = { ...this.surgeryReferenceDialogData };
    this.surgeryReferenceUIService.openDialog(SurgeryReferenceDialogPage.FractureSelect, this.surgeryReferenceDialogData);
  }

  get surgeryReferenceDialogData(): SurgeryReferenceDialogData {

    if (!this.clinicalCase) {
      return null;
    }

    this.clinicalCase.fractures = this.clinicalCase?.fractures || [];

    return {
      clinicalCase: this.clinicalCase,
      selectedFractures: this.clinicalCase?.fractures,
      approach: this.clinicalCase?.approach,
      preparation: this.clinicalCase?.preparation
    }
  }

  get showSRModule(): boolean {
    return this.surgeryReferenceDialogData?.selectedFractures?.length > 0 ||
      !!this.surgeryReferenceDialogData?.approach || !!this.surgeryReferenceDialogData?.preparation;
  }

  reloadClinicalCase(): void {
    this.fetchCase(this.caseId).then(res => {
      if (res) {
        this.clinicalCase = res;
        this.isLoadingFailed = false;
        this.initSharedWith();
        this.updateCaseReadinessState();
        this.checkAndShowAddSurgeryReferenceDialog();
      } else if (res === null) {
        this.appNavController.openExternallySharedCase(this.caseId);
      }
    }).catch(err => {
      this.isLoadingFailed = true;
      if (err?.error?.error?.info?.includes('NotFoundOrDeleted')) {
        this.appNavController.openFeedPage();
        this.toast.show('app.pages.CaseLibraryPage.deletedCase', null, ToastMode.ERROR);
      }
      console.error(err);
    });
  }

  get isBecomeAOMemberVisible(): boolean {
    return this.profile && !this.profile.isAOMember;
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get preOpImages(): Array<ImageAttachment> {
    if (this.clinicalCase && this.clinicalCase.preOpDocuments && this.clinicalCase.preOpDocuments.length > 0) {
      return getImagesWithCorrectUrlBasedOnWatermark(this.clinicalCase.preOpDocuments, this.clinicalCase.watermarked);
    }
    return [];
  }

  get showPreOpImages(): boolean {
    return this.preOpImages?.length > 0;
  }

  get intraOpImages(): Array<ImageAttachment> {
    if (this.clinicalCase && this.clinicalCase.intraOpDocuments && this.clinicalCase.intraOpDocuments.length > 0) {
      return getImagesWithCorrectUrlBasedOnWatermark(this.clinicalCase.intraOpDocuments, this.clinicalCase.watermarked);
    }
    return [];
  }

  get showIntraOpImages(): boolean {
    return this.intraOpImages?.length > 0;
  }

  get postOpImages(): Array<ImageAttachment> {
    if (this.clinicalCase && this.clinicalCase.postOpDocuments && this.clinicalCase.postOpDocuments.length > 0) {
      return getImagesWithCorrectUrlBasedOnWatermark(this.clinicalCase.postOpDocuments, this.clinicalCase.watermarked);
    }
    return [];
  }

  get showPostOpImages(): boolean {
    return this.postOpImages?.length > 0;
  }

  get clinicalFollowUpImages(): Array<ImageAttachment> {
    if (this.clinicalCase && this.clinicalCase.clinicalFollowUpDocuments && this.clinicalCase.clinicalFollowUpDocuments.length > 0) {
      return getImagesWithCorrectUrlBasedOnWatermark(this.clinicalCase.clinicalFollowUpDocuments, this.clinicalCase.watermarked);
    }
    return [];
  }

  get showClinicalFollowUpImages(): boolean {
    return this.clinicalFollowUpImages?.length > 0;
  }

  get showBottomBar(): boolean {
    return !this.isDesktop && this.isCaseOwner;
  }

  _updateImage(image: ImageAttachment): void {
    let foundImage: PersonalMediaGalleryDocument;
    if (this.clinicalCase.preOpDocuments) {
      foundImage = this.clinicalCase.preOpDocuments.find(doc => image._id === doc._id);
    }
    if (!foundImage && this.clinicalCase.intraOpDocuments) {
      foundImage = this.clinicalCase.intraOpDocuments.find(doc => image._id === doc._id);
    }
    if (!foundImage && this.clinicalCase.postOpDocuments) {
      foundImage = this.clinicalCase.postOpDocuments.find(doc => image._id === doc._id);
    }
    if (!foundImage && this.clinicalCase.clinicalFollowUpDocuments) {
      foundImage = this.clinicalCase.clinicalFollowUpDocuments.find(doc => image._id === doc._id);
    }
    if (foundImage) {
      foundImage.fullUrl = image.fullUrl;
      foundImage.previewUrl = image.previewUrl;
      foundImage.fullWatermarkUrl = image.fullWatermarkUrl;
      foundImage.watermarkedPreviewUrl = image.watermarkedPreviewUrl;
    }
  }

  onTopicChanged(topicsData: CaseClassificationFields): void {

    if (
      this.clinicalCase.classificationLevelOne === topicsData.classificationLevelOne &&
      this.clinicalCase.classificationLevelTwo === topicsData.classificationLevelTwo
    ) {
      return;
    }

    this.clinicalCase.classificationLevelOne = topicsData.classificationLevelOne;
    this.clinicalCase.classificationLevelTwo = topicsData.classificationLevelTwo;
    this.updateCaseReadinessState();

    const data: ClinicalCaseUpdateData = {};
    if (topicsData.classificationLevelOne && topicsData.classificationLevelTwo) {

      data.classificationLevelOne = topicsData.classificationLevelOne;
      data.classificationLevelTwo = topicsData.classificationLevelTwo;

      this.clinicalCaseService.updateCase(this.clinicalCase._id, data)
        .then(clinicalCase => {
          this.clinicalCase = clinicalCase;
          this.updateCaseReadinessState();
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  onCaseDocumentsChange(documents: Array<PersonalMediaGalleryDocument>): void {

    this.updatingClinicalCaseDocuments = true;

    if (!documents) {
      documents = [];
    }

    const data: ClinicalCaseUpdateData = {};
    const newDocumentAdded = documents?.length > this.clinicalCase?.attachments?.length;
    data.attachments = documents?.map(doc => doc._id) || [];

    this.clinicalCaseService.updateCase(this.clinicalCase._id, data)
      .then(clinicalCase => {
        this.clinicalCase = clinicalCase;
        if (newDocumentAdded) {
          setTimeout(() => {
            this.scrollToDocumentsSection();
          }, 100);
        }
      })
      .catch(err => {
        console.error(err);
      }).finally(() => {
        this.updatingClinicalCaseDocuments = false;
      });
  }

  onEditText(titleResourceId: string, fieldName: string, textLimit: number): void {
    this._editText(titleResourceId, fieldName, this.clinicalCase[fieldName], textLimit);
  }

  onDeleteDescription(questionResourceId: string, fieldName: string): void {
    const dialogData: ConfirmDialogData = {
      componentProps: {
        title: { translationKey: questionResourceId },
        message: null,
        actions: [
          {
            key: 'no',
            label: { translationKey: 'app.common.no' },
            className: 'secondary'
          },
          {
            key: 'yes',
            label: { translationKey: 'app.common.yes' },
            className: 'primary'
          },
        ]
      }
    };
    this.dialogs.createConfirmDialog(dialogData, true)
      .then(dlg => {
        dlg.onDidDismiss().then(res => {
          console.log(res);
          if (res && res?.data.actionKey === 'yes') {
            const data: ClinicalCaseUpdateData = {};
            data[fieldName] = '';
            this.clinicalCaseService.updateCase(this.clinicalCase._id, data)
              .then(clinicalCase => {
                this.clinicalCase = clinicalCase;
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
        dlg.present();
      });
  }

  fetchCase(caseId: string): Promise<ClinicalCase> {

    if (!caseId) {
      return Promise.resolve(null);
    }

    this.clinicalCaseLoading = true;

    const promise = this.clinicalCaseService.getCase(caseId);

    promise.finally(() => {
      this.clinicalCaseLoading = false;
    });

    return promise;
  }

  trackActionCaseOpened(caseId: string): void {
    const paramsToTrack: GenericTrackingParam = {
      objectId: caseId,
      objectType: 'case'
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.caseOpened,
      params: paramsToTrack
    };

    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track case opened action');
    });
  }

  showEditTextDialog(
    resourceId: string,
    text: string,
    textLimit: number,
    preventSaveWhenEmpty?: boolean,
    simpleTextArea?: boolean): Promise<string | null | void> {
    return new Promise(resolve => {

      return this.modalController.create({
        component: EditTextDialogComponent,
        componentProps: {
          headerResourceId: resourceId,
          text: text || '',
          textLimit: textLimit,
          preventSaveWhenEmpty: preventSaveWhenEmpty,
          simpleTextArea: simpleTextArea
        },
        cssClass: 'rounded-modal small-modal'
      }).then(modalEl => {
        modalEl.onWillDismiss()
          .then(res => {
            if (res.role === 'cancel') {
              return resolve();
            }
            if (res.role === 'save') {
              return resolve(res.data);
            }
            resolve();
          });
        return modalEl.present();
      });
    });
  }

  onDelete(): void {
    this.clinicalCaseUIService.showDeleteCaseConfirmDialog()
      .then(dlg => {
        return dlg.onDidDismiss();
      })
      .then(res => {
        if (res && res?.data.actionKey === 'yes') {
          this.clinicalCaseService.deleteCase(this.clinicalCase._id)
            .then(() => {
              this.clinicalCaseUIService.triggerCasesListChanged();
              this.navController.back();
            })
            .catch(() => {
              this.toast.show('app.pages.CaseLibraryPage.deleteSuccessMessage', null, ToastMode.ERROR);
            });
        }
      });
  }

  showOptionsForMobile(): void {
    this.infoSheetService.open(this.infoSheetId);
  }

  showAddDetailsMobile(): void {
    this.infoSheetService.open(this.addDetailsInfoSheetId);
  }

  openMessenger(): void {
    this.publicProfileService.getPublicProfile(this.clinicalCase?.ownerId).then(async (response) => {
      this.caseOwnerProfile = await response.result;
      this.appNavController.openMessenger(this.caseOwnerProfile?.connectionInfo?.id);
    }).catch(err => {
      console.error(err);
    });
  }

  async onAddImages(triggerActionOnInit?: ActionOnInit, stageOfTreatment?: StageOfTreatmentId): Promise<void> {

    this.clinicalCase.watermarked = this.clinicalCase?.watermarked ? true : false;
    const watermarked = this.clinicalCase?.watermarked;
    const clinicalCase = this.clinicalCase as any;
    const editCaseImagesDialogRes: EditCaseImagesDialogRes =
      await this.uiUtilsService.showEditCaseImagesDialog(clinicalCase as ClinicalCase, triggerActionOnInit, stageOfTreatment);

    if (editCaseImagesDialogRes?.updatedCase) {
      this.clinicalCaseService.emitClinicalCaseUpdated(editCaseImagesDialogRes?.updatedCase);
    }

    if (this.clinicalCase?.watermarked !== watermarked) {

      const data: ClinicalCaseUpdateData = {};
      data.watermarked = this.clinicalCase?.watermarked;

      this.clinicalCaseService.updateCase(this.clinicalCase._id, data)
        .then(updatedClinicalCase => {
          this.clinicalCase = updatedClinicalCase;
          this.updateCaseReadinessState();
        })
        .catch(err => {
          console.error(err);
          this.reloadClinicalCase();
        });
    } else {
      this.reloadClinicalCase();
    }
  }

  onAddDescription(titleResourceId: string, fieldName: string): void {
    this._editText(titleResourceId, fieldName, '', TextLimit.text_desc);
  }

  _editText(titleResourceId: string, fieldName: string, text: string, textLimit: number): void {

    const preventSaveWhenEmpty = fieldName === 'title' ? true : false;
    let simpleTextArea = false;

    switch (fieldName) {
      case 'title':
      case 'preOpDescription':
      case 'intraOpDescription':
      case 'postOpDescription':
      case 'clinicalFollowUpDescription':
        simpleTextArea = true;
        text = this.stripHtmlTagsFromString(text);
    }

    this.showEditTextDialog(titleResourceId, text, textLimit, preventSaveWhenEmpty, simpleTextArea)
      .then(result => {
        if (result === undefined) {
          return;
        }
        const data: ClinicalCaseUpdateData = {};
        data[fieldName] = result;
        this.clinicalCaseService.updateCase(this.clinicalCase._id, data)
          .then(clinicalCase => {
            this.clinicalCase = clinicalCase;
            this.updateCaseReadinessState();
          })
          .catch(err => {
            console.error(err);
          });
      });
  }

  onOpenGalleryModal(images: Array<ImageAttachment>): void {
    this.imageGalleryCaseStrategy.parentObject = this.clinicalCase;

    const galleryConfig: GalleryConfig = {
      images: images,
      // reviewMode: this.isCaseOwner,
      allowImageEdit: this.isCaseOwner,
      allowImageRemove: this.isCaseOwner,
      scope: FileSelectScope.CASE,
      imageGalleryStrategy: this.imageGalleryCaseStrategy,
      galleryId: this.galleryId
    };

    this.imageGalleryService.openImageGallery(galleryConfig)
      .then(res => {
        if (res && res.images) {
          const resImagesMap: Record<string, ImageAttachment> = {};
          res.images.forEach(resImage => {
            resImagesMap[resImage._id] = resImage;
          });
          // update possibly edited images
          images.forEach(image => {
            if (isAnyImage(image.mimeType)) {
              const resImage = resImagesMap[image._id];
              if (resImage) {
                image.fullUrl = resImage.fullUrl;
                image.fullWatermarkUrl = resImage.fullWatermarkUrl || resImage.fullUrl;
                image.watermarkedPreviewUrl = resImage.watermarkedPreviewUrl || resImage.previewUrl;
              }
            }
          });
        }
      });
  }

  onOpenMessenger(connectionId: string): void {
    this.appNavController.openMessenger(connectionId);
  }

  stripHtmlTagsFromString(content: string): string {
    return stripHtmlTagsFromString(content || '');
  }

  get isCaseOwner(): boolean {
    return this.clinicalCase && this.clinicalCase?.ownerId ?
      this.clinicalCase?.ownerId === this.profile?.userId : false;
  }

  onShare(): void {
    this.sharingUIService.showShareDialogForObject(this.clinicalCase).then(() => {
      this.initSharedWith();
    }).catch((err) => {
      console.error(err);
    });
  }

  onClinicalCaseUpdated(updatedClinicalCase: ClinicalCase): void {
    this.clinicalCase = updatedClinicalCase;
    this.updateCaseReadinessState();
  }

  exportCase(): void {
    this.clinicalCaseService.exportCase(this.clinicalCase?._id)
      .then((response: ExportClinicalCaseResponse) => {
        const successResponse = response as ExportClinicalCaseSuccessResponse;
        if (successResponse.success) {
          console.log('Case was exported');
        }
      }).catch(err => {
        console.log(err);
      })
  }

  confirmCommunityGuidelines(): Promise<void> {
    return this.dialogs.showConfirmCommunityGuidelines()
      .then(confirmed => {
        if (confirmed) {
          return this.onShare();
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  openCaseOwnerProfile(userId: string): void {
    this.appNavController.openPublicUserProfile(userId);
  }

  async bookmarkCase(): Promise<void> {
    if (!this.clinicalCase?.hasBookmarked) {
      this.clinicalCaseService.bookmarkCase(this.clinicalCase?._id, this.source)
        .then((responseObj) => {
          this.clinicalCase = responseObj;
        })
        .catch(err => {
          console.error(err);
          this.showError(err?.message);
        })
    } else {
      const confirmModal = await this.clinicalCaseUIService.showRemoveBookmarkCaseConfirmDialog();
      const res = await confirmModal.onDidDismiss();

      if (res && res?.data.actionKey === 'yes') {
        this.clinicalCaseService.removeBookmarkedCase(this.clinicalCase?._id, this.source)
          .then((responseObj: ClinicalCase) => {
            this.clinicalCase = responseObj;
          }).catch(err => {
            console.error(err);
            this.showError(err?.message);
          });
      }
    }
  }

  async showExportCaseDialog(): Promise<void> {
    const yesActionKey = 'yes';
    const cancelActionKey = 'cancel';

    const confirmDialogData: ConfirmDialogData = {
      componentProps: {
        image: 'md-icon-export-blue',
        title: {
          translationKey: 'app.pages.CasePage.exportCaseTitle'
        },
        message: {
          translationKey: 'app.pages.CasePage.exportCaseDescription'

        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.common.cancel'
            },
            className: 'secondary'
          },
          {
            key: yesActionKey,
            label: {
              translationKey: 'app.common.export'
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(confirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return;
      }

      if (res?.data?.actionKey === yesActionKey) {
        this.exportCase();
        this.showExportInProgressCaseDialog();
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
      return;
    }
  }

  async showExportInProgressCaseDialog(): Promise<void> {
    const yesActionKey = 'yes';

    const desc1 = await this.appTranslationService.get('app.pages.CasePage.exportInProgressDesc1');
    const desc2 = await this.appTranslationService.get('app.pages.CasePage.exportInProgressDesc2');
    const message = `${desc1} <span class="export-to-email">${this.profile?.email}</span> ${desc2}`;
    const safeHTML = this.sanitizer.bypassSecurityTrustHtml(message);

    const confirmDialogData: ConfirmDialogData = {
      componentProps: {
        image: 'md-icon-export-progress',
        title: {
          translationKey: 'app.pages.CasePage.exportInProgressTitle'
        },
        message: {
          safeHTML: safeHTML
        },
        actions: [
          {
            key: yesActionKey,
            label: {
              translationKey: 'app.common.close'
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(confirmDialogData);
    confirmModal.present();
  }

  async showTutorialDialog(): Promise<void> {
    const res = await this.uiUtilsService.showTutorialDialog({
      icon: 'md-icon-clap',
      title: this.appTranslationService.instant('app.dialogs.TutorialDialog.case-folio-finish-dialog-title'),
      subtitle: this.appTranslationService.instant('app.dialogs.TutorialDialog.case-folio-finish-dialog-subtitle'),
      cancelButton: this.appTranslationService.instant('app.dialogs.TutorialDialog.case-folio-finish-dialog-cancel-button'),
      proceedButton: this.appTranslationService.instant('app.dialogs.TutorialDialog.case-folio-finish-dialog-proceed-button'),
    });

    this.tutorialService.markAsFinished(TUTORIAL_KEY);

    if (res) { // add more details clicked
      this.showAddDetailsMobile();
    }
  }

  async initSharedWith(): Promise<void> {

    if (!this.caseId || !this.isCaseOwner) {
      this.clinicalCaseSharedWith = [];
      return;
    }

    try {

      this.clinicalCaseSharedWithSuccessResponse = (await this.clinicalCaseService.getSharedWith(this.caseId, 0, this.isDesktop ? 5 : 4));

      let clinicalCaseSharedWith = this.clinicalCaseSharedWithSuccessResponse?.result;

      clinicalCaseSharedWith = clinicalCaseSharedWith?.filter((sharedWith: SharedWith) =>
        sharedWith.type === SharedWithType.GROUP || sharedWith.type === SharedWithType.CONTACT);

      this.clinicalCaseSharedWith = clinicalCaseSharedWith;
    } catch (err) {
      const errorMessage = err?.error?.message?.errfor?.ownerAccessOnly || err;
      this.toast.show(errorMessage, null, ToastMode.ERROR);
    }
  }

  get hideSharedWithSliderSeeAll(): boolean {
    return this.clinicalCaseSharedWithSuccessResponse?.totalItemsCount <= (this.isDesktop ? 5 : 4) ? true : false;
  }

  get showSharedWithSlider(): boolean {
    return this.clinicalCase &&
      this.isCaseOwner &&
      this.clinicalCaseSharedWith &&
      this.clinicalCaseSharedWith.length ? true : false;
  }

  get showReplyBtn(): boolean {
    return this.clinicalCase &&
      !this.isCaseOwner &&
      this.clinicalCase?.owner?.connectionStatus === 'connected' ? true : false;
  }

  seeAllSharedWith(): void {
    this.contextDialogs.showClinicalCaseSharedWith(this.caseId);
  }

  updateCaseReadinessState(): void {

    // stage1: title and topics
    if (!this.clinicalCase?.title?.length
      && (!this.clinicalCase?.classificationLevelOne?.length ||
        !this.clinicalCase?.classificationLevelTwo?.length)) {
      this.caseReadinessIndicatorState.stage1 = CaseReadinessStageState.STATE_1;
    } else if (this.clinicalCase?.title?.length &&
      this.clinicalCase?.classificationLevelOne?.length &&
      this.clinicalCase?.classificationLevelTwo?.length) {
      this.caseReadinessIndicatorState.stage1 = CaseReadinessStageState.STATE_3;
    } else {
      this.caseReadinessIndicatorState.stage1 = CaseReadinessStageState.STATE_2;
    }

    // stage2 images

    if (!this.caseImages?.length) {
      this.caseReadinessIndicatorState.stage2 = CaseReadinessStageState.STATE_1;
    } else {
      this.caseReadinessIndicatorState.stage2 = CaseReadinessStageState.STATE_3;
    }

    // stage3 details
    if (!this.clinicalCase?.description?.length &&
      !this.clinicalCase?.complainMedicalCondition?.length &&
      !this.clinicalCase?.clinicalExam?.length &&
      !this.clinicalCase?.notes?.length) {
      this.caseReadinessIndicatorState.stage3 = CaseReadinessStageState.STATE_1;
    } else if (this.clinicalCase?.description?.length ||
      this.clinicalCase?.complainMedicalCondition?.length ||
      this.clinicalCase?.clinicalExam?.length ||
      this.clinicalCase?.notes?.length) {
      this.caseReadinessIndicatorState.stage3 = CaseReadinessStageState.STATE_3;
    }
  }

  get caseImages(): Array<PersonalMediaGalleryDocument> {
    return [
      ...this.clinicalCase.preOpDocuments || [],
      ...this.clinicalCase.intraOpDocuments || [],
      ...this.clinicalCase.postOpDocuments || [],
      ...this.clinicalCase.clinicalFollowUpDocuments || []
    ];
  }

  addDocuments(): void {
    this.documentsUploadEditPreviewComponent.addDocuments();
  }

  get selectableFracture(): FractureAndTreatment {
    if (this.surgeryReferenceDialogData && this.surgeryReferenceDialogData.selectedFractures.length) {
      const lastItemIndex = this.surgeryReferenceDialogData.selectedFractures.length - 1;
      const selectableFracture = this.surgeryReferenceDialogData.selectedFractures[lastItemIndex];
      return selectableFracture;
    }

    return null;
  }

  addNewModule(): void {
    this.openSurgeryReferenceDialog();
  }

  removeModule(item: Fracture | Treatment | Approach | Preparation | FractureAndTreatment): Promise<void> {

    if (this.removingModule) {
      return;
    }

    this.removingModule = true;

    this.surgeryReferenceUIService.removeModule(item, this.clinicalCase?._id).then(() => {

      if ((item as any)?.fracture && (item as any)?.treatment) { // Treatment but also remove fracture
        this._removeTreatment(item as FractureAndTreatment);
      } else {
        switch ((item as any).contentType) {
          case ContentType.DIAGNOSIS: // Fracture
            this._removeFracture(item as Fracture);
            break;
          case ContentType.APPROACH:
            delete this.clinicalCase?.approach;
            break;
          case ContentType.PREPARATION:
            delete this.clinicalCase?.preparation;
            break;
        }
      }
    }).catch((err) => {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    }).finally(() => {
      this.removingModule = false;
    });
  }

  _removeTreatment(item: FractureAndTreatment): void {
    if (this.clinicalCase && this.clinicalCase?.fractures?.length) {
      const treatmentIndex = this.clinicalCase?.fractures.findIndex((selectedFractures: FractureAndTreatment) =>
        selectedFractures?.fracture?._id === (item as FractureAndTreatment)?.fracture?._id &&
        selectedFractures?.treatment?._id === (item as FractureAndTreatment)?.treatment?._id);

      if (treatmentIndex > -1) {
        this.clinicalCase?.fractures.splice(treatmentIndex, 1);
      }
    }
  }

  _removeFracture(item: Fracture): void {
    if (this.clinicalCase && this.clinicalCase?.fractures?.length) {
      const fractureIndex = this.clinicalCase?.fractures
        .findIndex((fractureAndTreatment: FractureAndTreatment) =>
          fractureAndTreatment?.fracture?._id === (item as Fracture)?._id);

      if (fractureIndex > -1) {
        this.clinicalCase?.fractures.splice(fractureIndex, 1);
      }
    }
  }

  private checkAndShowTutorialFinishDialog(): void {
    this.tutorialService.checkShouldShowEndDialog(TUTORIAL_KEY).then(shouldShow => {
      if (shouldShow) {
        this.showTutorialDialog();
      }
    }).catch(() => {
      // don't show the dialog
    });
  }

  private scrollToDocumentsSection(): void {

    if (!this.clinicalCase?.attachments?.length || !this.documentsContainer) {
      return;
    }

    const documentsContainerHeight = this.documentsContainer?.nativeElement?.clientHeight;
    const scrollToYPosition = this.documentsContainer?.nativeElement?.offsetTop + documentsContainerHeight;
    this.content.scrollToPoint(null, scrollToYPosition);
  }

  private scrollToSurgeryReferenceModules(): void {
    if (this.checkIfNewSurgeryReferenceModuleIsAdded) {
      const surgeryReferenceContainerHeight = this.surgeryReferenceContainer?.nativeElement?.clientHeight;
      const scrollToYPosition = this.surgeryReferenceContainer?.nativeElement?.offsetTop + surgeryReferenceContainerHeight;
      this.content.scrollToPoint(null, scrollToYPosition);
    }
  }

  private checkIfNewSurgeryReferenceModuleIsAdded(): boolean {

    if (this.checkIfNewSurgeryReferenceFractureIsAdded) {
      return true;
    }

    if (this.surgeryReferenceDialogData?.approach &&
      !this.prevSurgeryReferenceDialogData?.approach) {
      return true;
    }

    if (this.surgeryReferenceDialogData?.preparation &&
      !this.prevSurgeryReferenceDialogData?.preparation) {
      return true;
    }

    return false;
  }

  private checkIfNewSurgeryReferenceFractureIsAdded(): boolean {

    if (this.surgeryReferenceDialogData?.selectedFractures?.length) {
      return false;
    }

    if (this.surgeryReferenceDialogData?.selectedFractures?.length >
      this.prevSurgeryReferenceDialogData?.selectedFractures?.length) {
      return true;
    }

    let newFractureAdded = false;

    this.surgeryReferenceDialogData?.selectedFractures?.forEach(fractureObj => {
      const index =
        this.prevSurgeryReferenceDialogData?.selectedFractures.findIndex(f => {
          return f?.fracture?._id === fractureObj?.fracture?._id;
        });

      if (index === -1) {
        newFractureAdded = true;
      }
    });

    return newFractureAdded;
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  private get galleryId(): string {
    return this.clinicalCase._id;
  }

  private async executeImageGalleryAction(action: ActionContent): Promise<any> {

    if (this.performingImageGalleryAction) {
      return;
    }

    const imageId = action?._id;
    const galleryId = action.galleryId;

    if (this.galleryId !== galleryId) {
      return;
    }

    if (action.action === ImageGalleryActions.REMOVE_IMAGE) {
      this.performingImageGalleryAction = true;
      this.deleteImageFromClinicalCase(imageId).then((documents: Array<PersonalMediaGalleryDocumentWithStageOfTreatment>) => {

        const galeryImages = documents.map(image => {
          return {
            _id: image._id,
            fileName: image.fileName,
            url: this.clinicalCase?.watermarked ? image?.watermarkedPreviewUrl : image?.previewUrl,
            fullImageUrl: this.clinicalCase?.watermarked ? image?.fullWatermarkUrl : image?.fullUrl,
            mimeType: image.mimeType,
            videoUrl: isVideo(image.mimeType) ? image?.fullUrl : null
          } as ImageAttachment;
        });

        this.imageGalleryService.updateGalleryImages(galeryImages);
        this.imageGalleryCaseStrategy.parentObject = this.clinicalCase;
      }).finally(() => {
        this.performingImageGalleryAction = false;
      })
    }
  }

  deleteImageFromClinicalCase(imageId: string): Promise<Array<PersonalMediaGalleryDocumentWithStageOfTreatment>> {

    const documents = this.findAndRemoveImageInClinicalCaseImages(imageId, this.clinicalCase?.preOpDocuments) ||
      this.findAndRemoveImageInClinicalCaseImages(imageId, this.clinicalCase?.intraOpDocuments) ||
      this.findAndRemoveImageInClinicalCaseImages(imageId, this.clinicalCase?.postOpDocuments) ||
      this.findAndRemoveImageInClinicalCaseImages(imageId, this.clinicalCase?.clinicalFollowUpDocuments);

    const clinicalCaseData = {
      preOpDocuments: [],
      intraOpDocuments: [],
      postOpDocuments: [],
      clinicalFollowUpDocuments: []
    };

    clinicalCaseData.preOpDocuments = this.clinicalCase.preOpDocuments?.map(doc => doc._id);
    clinicalCaseData.intraOpDocuments = this.clinicalCase.intraOpDocuments?.map(doc => doc._id);
    clinicalCaseData.postOpDocuments = this.clinicalCase.postOpDocuments?.map(doc => doc._id);
    clinicalCaseData.clinicalFollowUpDocuments = this.clinicalCase.clinicalFollowUpDocuments?.map(doc => doc._id);

    return new Promise((resolve, reject) => {
      this.clinicalCaseService.updateCase(this.clinicalCase._id, clinicalCaseData).then((clinicalCase) => {
        this.clinicalCase = clinicalCase;
        resolve(documents);
      }).catch(err => {
        this.showError(err?.message);
        reject();
      });
    })
  }

  findAndRemoveImageInClinicalCaseImages(
    imageId: string,
    documents: Array<PersonalMediaGalleryDocumentWithStageOfTreatment>): Array<PersonalMediaGalleryDocumentWithStageOfTreatment> {

    let clinicalCaseImageIndex = -1;

    clinicalCaseImageIndex = documents?.findIndex(img => img._id === imageId);

    if (clinicalCaseImageIndex >= 0) {
      documents.splice(clinicalCaseImageIndex, 1);
      return documents;
    }

    return null;
  }

  async reorderMedia(): Promise<void> {

    const preOpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.preOp'),
      id: StageOfTreatmentId.PRE_OP,
      items: this.clinicalCase?.preOpDocuments || []
    };

    const intraOpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.intraOp'),
      id: StageOfTreatmentId.INTRA_OP,
      items: this.clinicalCase?.intraOpDocuments || []
    };

    const postOpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.postOp'),
      id: StageOfTreatmentId.POST_OP,
      items: this.clinicalCase?.postOpDocuments || []
    };

    const clinicalFollowUpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.followUp'),
      id: StageOfTreatmentId.FOLLOW_UP,
      items: this.clinicalCase?.clinicalFollowUpDocuments || []
    };

    try {
      const reorderedImages = await this.uiUtilsService.showCaseMediaReorderDialog([preOpGroup,
        intraOpGroup,
        postOpGroup,
        clinicalFollowUpGroup], 'caseDetails', this.clinicalCase?.watermarked);

      if (!reorderedImages?.length) {
        return;
      }

      const updateImagesData = {
        preOpDocuments: reorderedImages.find(group => group?.id === StageOfTreatmentId.PRE_OP)?.items?.map(item => item?._id) || [],
        intraOpDocuments: reorderedImages.find(group => group?.id === StageOfTreatmentId.INTRA_OP)?.items?.map(item => item?._id) || [],
        postOpDocuments: reorderedImages.find(group => group?.id === StageOfTreatmentId.POST_OP)?.items?.map(item => item?._id) || [],
        clinicalFollowUpDocuments:
          reorderedImages.find(group => group?.id === StageOfTreatmentId.FOLLOW_UP)?.items?.map(item => item?._id)
          || []
      };

      this.clinicalCaseService.updateCase(this.clinicalCase._id, updateImagesData)
        .then(clinicalCase => {
          this.clinicalCase = clinicalCase;
          this.updateCaseReadinessState();
        })
        .catch(err => {
          console.error(err);
          if (err?.error?.error?.message?.errfor?.message) {
            this.showError(err?.error?.error?.message?.errfor?.message);
          }
        });
    } catch (err) {
      console.error(err);
      if (err?.error?.error?.message?.errfor?.message) {
        this.showError(err?.error?.error?.message?.errfor?.message);
      }
    }
  }

  async toggleCaseFeaturedStatus(): Promise<void> {

    if (this.caseFeatureStatusChanging) {
      return;
    }

    this.caseFeatureStatusChanging = true;

    if (this.clinicalCase.isFeatured) {
      const shouldProceed = await this.uiUtilsService.showUnfeatureCasePrompt();

      if (shouldProceed) {
        this.clinicalCaseService.unfeatureCase(this.clinicalCase?._id).then(() => {
          this.clinicalCase.isFeatured = false;
          this.clinicalCaseService.emitClinicalCaseUpdated(this.clinicalCase);
          this.uiUtilsService.showCaseUnfeatureSuccessToast();
          this.trackActionCaseFeaturedStatusChanged(false);
        }).finally(() => {
          this.caseFeatureStatusChanging = false;
        })
      } else {
        this.caseFeatureStatusChanging = false;
      }
    } else {
      const shouldProceed = await this.uiUtilsService.showFeatureCasePrompt();

      if (shouldProceed) {
        this.clinicalCaseService.featureCase(this.clinicalCase?._id).then(() => {
          this.clinicalCase.isFeatured = true;
          this.clinicalCaseService.emitClinicalCaseUpdated(this.clinicalCase);
          this.uiUtilsService.showCaseFeatureSuccessToast();
          this.trackActionCaseFeaturedStatusChanged(true);
        }).finally(() => {
          this.caseFeatureStatusChanging = false;
        })
      } else {
        this.caseFeatureStatusChanging = false;
      }
    }
  }

  trackActionCaseFeaturedStatusChanged(featured: boolean): void {
    const paramsToTrack: GenericTrackingParam = {
      objectId: this.clinicalCase?._id,
      objectType: 'case'
    };

    if (this.source) {
      paramsToTrack.source = this.source;
    }

    const trackData: TrackingRequest = {
      action: featured ? ActionTracked.caseFeatured : ActionTracked.caseUnfeatured,
      params: paramsToTrack
    };

    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track case featured status changed action');
    });
  }

  applaudClicked(): void {

    if (this.applaudStatusChanging) {
      return;
    }

    this.applaudStatusChanging = true;

    if (this.clinicalCase?.hasLiked) {
      this.clinicalCaseService.unlikeCase(this.clinicalCase?._id).then(res => {
        this.clinicalCase = (res as LikeUnlikeSuccessResponse).result as ClinicalCase;
        this.clinicalCaseService.emitClinicalCaseUpdated(this.clinicalCase);
      }).finally(() => {
        this.applaudStatusChanging = false;
      });
    } else {
      this.clinicalCaseService.likeCase(this.clinicalCase?._id).then(res => {
        this.clinicalCase = (res as LikeUnlikeSuccessResponse).result as ClinicalCase;
        this.clinicalCaseService.emitClinicalCaseUpdated(this.clinicalCase);
      }).finally(() => {
        this.applaudStatusChanging = false;
      });
    }
  }

  get source(): ActionSource {
    return ActionSource.caseDetailsPage;
  }
}
