// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 20px;
  --padding-end: 20px;
  --background-color: var(--mi-white-color);
}

.create-edit-case-post-bottom-bar-container {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.create-edit-case-post-bottom-bar-container .bottom-bar {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  align-content: center;
  padding: var(--padding-top) var(--padding-end) calc(var(--ion-safe-area-bottom) + var(--padding-bottom)) var(--padding-start);
  background-color: var(--background-color);
}
.create-edit-case-post-bottom-bar-container .bottom-bar.extra-padding-when-keyboard-showed-on-ios {
  padding: var(--padding-top) var(--padding-end) var(--ion-safe-area-bottom) var(--padding-start);
}
.create-edit-case-post-bottom-bar-container .bottom-bar .left-container {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  --icons-color: var(--mi-grey-color-13);
}
@media all and (min-width: 566px) {
  .create-edit-case-post-bottom-bar-container .bottom-bar .left-container {
    -moz-column-gap: 35px;
         column-gap: 35px;
  }
}
.create-edit-case-post-bottom-bar-container .bottom-bar .left-container app-group-image-button {
  --group-image-button-icon-color: var(--icons-color);
}
.create-edit-case-post-bottom-bar-container .bottom-bar .left-container app-video-button {
  --video-button-icon-color: var(--icons-color);
}
.create-edit-case-post-bottom-bar-container .bottom-bar .left-container app-group-document-button {
  --group-document-button-icon-color: var(--icons-color);
}
.create-edit-case-post-bottom-bar-container .bottom-bar .left-container app-group-case-button {
  --group-case-button-icon-color: var(--icons-color);
}
.create-edit-case-post-bottom-bar-container .bottom-bar .left-container app-watermark-button {
  --watermark-button-icon-color: var(--icons-color);
}
.create-edit-case-post-bottom-bar-container .bottom-bar .left-container app-emoji-picker {
  --icon-button-icon-color: var(--icons-color);
}
.create-edit-case-post-bottom-bar-container .bottom-bar .right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.create-edit-case-post-bottom-bar-container .bottom-bar .right-container .create-group-post-settings-btn {
  justify-self: flex-end;
}

app-info-sheet {
  --info-sheet-content-grid-row-gap: 20px;
  --info-sheet-padding-bottom: 20px;
}
app-info-sheet ::ng-deep .info-sheet-container .info-sheet {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}
app-info-sheet app-info-sheet-action.disabled {
  opacity: 0.5;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
