import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable, pipe, Subject } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { toAuthRequestParams } from './auth/logic/auth-logic.utils';


// models
import appConfig from 'src/config/config';
import { AuthRequestParams } from './auth/logic/auth-logic.service.interface';
import { Comment, UploadCommentAttachmentResponse } from './yeti-protocol/comments';
import { ExternalArticle, UploadPdfArticleRequest, UploadPdfArticleResponse } from './yeti-protocol/external-article';

// services
import { AuthService } from './auth/auth.service';
import { SchemaValidatorService } from './schema-validator.service';
import { LoadingService } from './loadingService/loadingService.service';
import { VerificationRequest, VerificationRequestParams, VerificationResponse } from './yeti-protocol/verification';
import {
  UpdatePersonalMediaGalleryDocumentRequestParams,
  UpdatePersonalMediaGalleryDocumentResponse,
  UploadPersonalMediaGalleryDocumentsRequestParams,
  UploadPersonalMediaGalleryDocumentsResponse,
  UploadPPTDocumentRequestParams,
  UploadPPTDocumentResponse
} from './yeti-protocol/personal-media-gallery';
import { ActionTracked, TrackingRequest, UploadMediaTrackingParam } from './yeti-protocol/tracking';
import { FileSelectScope } from '../modules/file-select/services/file-select.service';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';
import { TRACKING_SERVICE, TrackingService } from './tracking/tracking.model';
import { UploadFile } from './yeti-protocol/upload';
import { AuthenticationHeaders, MIAuthService } from './auth/mi/mi-auth.service';
import * as _ from 'lodash';
import { HttpClient, HttpEventType } from '@angular/common/http';

export enum UploadTarget {
  USER_PROFILE = 'userProfileUploadUrl',
  GROUP_IMAGE = 'groupImageUploadUrl',
  GROUP_POST_ATTACHMENTS = 'groupPostAttachmentsUrl',
  COMMENT_ATTACHMENTS = 'commentAttachmentsUrl',
  VERIFICATION_REUEST = 'verificationRequestUrl',
  PDF_ARTICLE = 'pdfArticleUploadUrl',
  PERSONAL_MEDIA_GALLERY = 'personalMediaGalleryUrl',
  AI_KNEE_MODEL = 'aiKneeModel',
  AI_PELVIS_FRACTURES_MODEL = 'aiPelvisFracturesModel',
  PPT = 'ppt'
}

function toBlob(data: string | File): Blob {
  if (typeof data === 'string') {
    return new Blob([data]);
  }
  return data;
}

export interface UploadPPTDocumentResponseAndProgress {
  responsePromise: Promise<UploadPPTDocumentResponse>;
  progress: Observable<number>;
}


@Injectable({
  providedIn: 'root'
})
export class UploadService {
  config = {
    userProfileUploadUrl: `${appConfig.serverUrl}profile/image`,
    groupImageUploadUrl: `${appConfig.chatterUrl}groups`,
    groupPostAttachmentsUrl: `${appConfig.chatterUrl}posts`,
    commentAttachmentsUrl: `${appConfig.backendUrl}ionic/comment`,
    verificationRequestUrl: `${appConfig.serverUrlIonic}profile/verification/image`,
    pdfArticleUploadUrl: `${appConfig.backendUrl}articles`,
    personalMediaGalleryUrl: `${appConfig.chatterUrl}documents`,
    aiKneeModel: `${appConfig.aiUrl}/knee/`,
    aiPelvisFracturesModel: `${appConfig.aiUrl}/pelvis-fractures/`,
    ppt: `${appConfig.chatterUrl}documents/ppt`,
  }

  constructor(
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private schemaValidator: SchemaValidatorService,
    public loadingService: LoadingService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private miAuthService: MIAuthService,
    private httpClient: HttpClient
  ) {
  }

  /** @method
    * @desc File uploading for generic interface.
    * @param target - target endpoint for uploading
    * @param data - browser File or content as Base64 encoded string
    */
  upload(target: UploadTarget, data: UploadFile): Observable<any> {
    const uploadUrl = this.config[target];
    const obs = this.uploadTo(uploadUrl, data)
    obs.pipe(
      catchError(err => {
        this.trackUploadFail(data.fileName, data.mimeType, this.getScopeFromUploadTarget(target), JSON.stringify(err));
        return err;
      })
    );
    return obs;
  }

  uploadTo(uploadUrl: string, data: UploadFile): Observable<any> {
    const formData = new FormData();
    formData.append(data.key, toBlob(data.file), data.fileName);

    const options = {
      reportProgress: true,
    };

    return this.authService.securePost(uploadUrl, formData, options);
  }

  uploadGroupImage(data: UploadFile, appId: string, groupId: string): Observable<any> {
    const uploadUrl = this.config[UploadTarget.GROUP_IMAGE] + `/${groupId}/attachment`;
    const requestParams: AuthRequestParams = {
      appId: appId
    };
    const formData = new FormData();
    formData.append(data.key, toBlob(data.file), data.fileName);

    const options = {
      params: toAuthRequestParams(requestParams),
      reportProgress: true,
    };

    const obs = this.authService.securePut(uploadUrl, formData, options);

    obs
      .pipe(
        catchError(err => {
          this.trackUploadFail(data.fileName, data.mimeType, FileSelectScope.GROUP, JSON.stringify(err));
          return err;
        })
      );

    return obs;
  }

  uploadCommentAttachments(filesData: Array<UploadFile>, comment: Comment): Promise<UploadCommentAttachmentResponse> {
    const commentId = comment?._id;
    const appId = this.contextService.currentContext.key;
    const uploadUrl = this.config[UploadTarget.COMMENT_ATTACHMENTS] + `/${commentId}`;

    const requestParams: AuthRequestParams = {
      appId: appId
    };

    const formData = new FormData();

    filesData.forEach((file: UploadFile) => {
      formData.append('files', toBlob(file.file), file.fileName);
    });

    formData.append('content', comment.content || '');
    formData.append('url', comment.url || '');

    const options = {
      params: toAuthRequestParams(requestParams),
      reportProgress: true,
    };

    const uploadPromise = firstValueFrom(
      this.authService.securePut<any, UploadCommentAttachmentResponse>(uploadUrl, formData, options)
        .pipe(
          this.schemaValidator.isValidOperator<UploadCommentAttachmentResponse>('UploadCommentAttachmentResponse')
        )).finally(() => {
          this.loadingService.dismiss();
        });

    uploadPromise.catch(err => {
      const fileName = this.getTrackingParamFileName(filesData);
      const mimeType = this.getTrackingParamFileMimeType(filesData);
      this.trackUploadFail(fileName, mimeType, FileSelectScope.COMMENT, JSON.stringify(err));
    });

    return uploadPromise;
  }

  async uploadVerificationFile(data: VerificationRequest): Promise<VerificationResponse> {

    let appId = this.contextService.currentContext.key;

    try {
      appId = (await this.contextService.getCurrentContext())?.key;
    } catch (err) {
      appId = this.contextService.currentContext.key;
    }

    const uploadUrl = this.config[UploadTarget.VERIFICATION_REUEST];

    const requestParams: VerificationRequestParams = {
      appId: appId
    };

    const formData = new FormData();

    formData.append('image', toBlob(data.image.file), data.image.fileName);
    formData.append('university', data.university || '');
    formData.append('graduate', data.graduate || '');
    formData.append('professional', data.professional || '');

    const options = {
      params: toAuthRequestParams(requestParams),
      reportProgress: true,
    };

    const uploadPromise = firstValueFrom(this.authService.securePost<any, VerificationResponse>(uploadUrl, formData, options).pipe(
      this.schemaValidator.isValidOperator<VerificationResponse>('VerificationResponse')
    )).finally(() => {
      this.loadingService.dismiss();
    });

    uploadPromise.catch(err => {
      this.trackUploadFail(data.image.fileName, data.image.mimeType, FileSelectScope.VERIFICATION, JSON.stringify(err));
    });

    return uploadPromise;
  }

  uploadPdfArticle(data: UploadFile, article: ExternalArticle, appId: string): Promise<string> {
    const uploadUrl = this.config[UploadTarget.PDF_ARTICLE];
    const requestParams: AuthRequestParams = {
      appId: appId
    };
    const formData = new FormData();
    formData.append(data.key, toBlob(data.file), data.fileName);
    formData.append('mimeType', data.mimeType);
    for (const prop in article) {
      if (article.hasOwnProperty(prop)) {
        formData.append(prop, article[prop]);
      }
    }

    const options = {
      params: toAuthRequestParams(requestParams),
      reportProgress: true,
    };

    return firstValueFrom(this.authService.securePost<UploadPdfArticleRequest, UploadPdfArticleResponse>(uploadUrl, formData, options)
      .pipe(
        map(res => {
          if (res.success && res.article) {
            return res.article._id;
          }
        })
      ));
  }

  uploadPersonalMediaGalleryDocuments(
    filesData: Array<UploadFile>,
    scope: FileSelectScope): Promise<UploadPersonalMediaGalleryDocumentsResponse> {

    const appId = this.contextService.currentContext.key;
    const uploadUrl = this.config[UploadTarget.PERSONAL_MEDIA_GALLERY];
    const requestParams: UploadPersonalMediaGalleryDocumentsRequestParams = {
      appId: appId
    };

    const formData = new FormData();

    filesData.forEach((file: UploadFile) => {
      formData.append(file.key, toBlob(file.file), file.fileName);
    });

    const options = {
      params: toAuthRequestParams(requestParams),
      reportProgress: true,
    };

    const uploadPromise = firstValueFrom(
      this.authService.securePost<any, UploadPersonalMediaGalleryDocumentsResponse>(uploadUrl, formData, options)
        .pipe(
          this.schemaValidator.isValidOperator<UploadPersonalMediaGalleryDocumentsResponse>('UploadPersonalMediaGalleryDocumentsResponse')
        )).finally(() => {
          this.loadingService.dismiss();
        });

    uploadPromise.catch(err => {
      const fileName = this.getTrackingParamFileName(filesData);
      const mimeType = this.getTrackingParamFileMimeType(filesData);
      this.trackUploadFail(fileName, mimeType, scope, JSON.stringify(err));
    });

    return uploadPromise;
  }

  updatePersonalMediaGalleryDocument(
    fileData: UploadFile,
    documentId: string,
    scope: FileSelectScope): Promise<UpdatePersonalMediaGalleryDocumentResponse> {

    const appId = this.contextService.currentContext.key;
    const uploadUrl = `${this.config[UploadTarget.PERSONAL_MEDIA_GALLERY]}/${documentId}`;
    const requestParams: UpdatePersonalMediaGalleryDocumentRequestParams = {
      appId: appId
    };

    const formData = new FormData();

    formData.append(fileData.key, toBlob(fileData.file), fileData.fileName);

    const options = {
      params: toAuthRequestParams(requestParams),
      reportProgress: true,
    };

    const uploadPromise = firstValueFrom(
      this.authService.securePut<any, UpdatePersonalMediaGalleryDocumentResponse>(uploadUrl, formData, options)
        .pipe(
          this.schemaValidator.isValidOperator<UpdatePersonalMediaGalleryDocumentResponse>('UpdatePersonalMediaGalleryDocumentResponse')
        )).finally(() => {
          this.loadingService.dismiss();
        });

    uploadPromise.catch(err => {
      const fileName = this.getTrackingParamFileName([fileData]);
      const mimeType = this.getTrackingParamFileMimeType([fileData]);
      this.trackUploadFail(fileName, mimeType, scope, JSON.stringify(err));
    });

    return uploadPromise;
  }

  trackUploadFail(fileName: string, mimeType: string, scope: FileSelectScope, error: string): Promise<void> {
    const paramsToTrack: UploadMediaTrackingParam = {
      objectId: fileName,
      objectType: mimeType,
      scope: scope,
      uploadFailed: true, // dont think we need this ... since we send this only if upload fails
      reason: 'backendError',
      errorInfo: error
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.uploaded,
      params: paramsToTrack
    };

    return this.trackingService.track(trackData).catch(_err => {
      console.error('Something went wrong on upload action: ' + _err);
    });
  }

  private getTrackingParamFileName(selectedFiles: Array<UploadFile>): string {

    if (!selectedFiles || !selectedFiles?.length) {
      return '';
    }

    const fileNames = [];
    let fileName = '';

    selectedFiles.forEach((selectedFile: UploadFile) => {
      fileNames.push(selectedFile?.fileName);
    });

    fileName = fileNames.length > 1 ? fileNames.join(',') : fileNames[0];
    return fileName;
  }

  private getTrackingParamFileMimeType(selectedFiles: Array<UploadFile>): string {

    if (!selectedFiles || !selectedFiles?.length) {
      return '';
    }

    const fileMimeTypes = [];
    let fileMimeType = '';

    selectedFiles.forEach((selectedFile: UploadFile) => {
      fileMimeTypes.push(selectedFile?.mimeType);
    });

    fileMimeType = fileMimeTypes.length > 1 ? fileMimeTypes.join(',') : fileMimeTypes[0];
    return fileMimeType;
  }

  private getScopeFromUploadTarget(target: UploadTarget): FileSelectScope {
    switch (target) {
      case UploadTarget.USER_PROFILE:
        return FileSelectScope.PROFILE_IMAGE;
      case UploadTarget.GROUP_IMAGE:
        return FileSelectScope.GROUP;
      case UploadTarget.GROUP_POST_ATTACHMENTS:
        return FileSelectScope.NA;
      case UploadTarget.COMMENT_ATTACHMENTS:
        return FileSelectScope.COMMENT;
      case UploadTarget.VERIFICATION_REUEST:
        return FileSelectScope.VERIFICATION;
      case UploadTarget.PDF_ARTICLE:
        return FileSelectScope.NA;
      case UploadTarget.PERSONAL_MEDIA_GALLERY:
        return FileSelectScope.NA;
      case UploadTarget.AI_KNEE_MODEL:
        return FileSelectScope.NA;
      case UploadTarget.AI_PELVIS_FRACTURES_MODEL:
        return FileSelectScope.NA;
      default:
        return FileSelectScope.NA;
    }
  }

  uploadPPTDocument(
    filesData: Array<UploadFile>
  ): UploadPPTDocumentResponseAndProgress {

    const appId = this.contextService.currentContext.key;
    const uploadUrl = this.config[UploadTarget.PPT];
    const requestParams: UploadPPTDocumentRequestParams = {
      appId: appId
    };

    const formData = new FormData();

    filesData.forEach((file: UploadFile) => {
      formData.append(file.key, toBlob(file.file), file.fileName);
    });

    let options: any = {
      params: toAuthRequestParams(requestParams),
      reportProgress: true,
      observe: 'events'
    };

    const progressSubject = new Subject<number>();

    const uploadPromise = this.miAuthService.getAuthenticationHeaders().then((authHeaders: AuthenticationHeaders) => {
      options = options || {};

      if (!options.headers) {
        options.headers = {};
      }
      _.extend(options.headers, authHeaders);

      return firstValueFrom(
        this.httpClient.post<Response>(uploadUrl, formData, options)
          .pipe(
            filter((event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                const progress = Math.round(100 * event.loaded / event.total);
                progressSubject.next(progress);
              }

              if (event.type === HttpEventType.Response) {
                return event;
              }
            }),
            map((event: any) => {
              return (event?.body as UploadPPTDocumentResponse);
            }),
            pipe(
              // this.schemaValidator.isValidOperator<UploadPPTDocumentResponse>('UploadPPTDocumentResponse')
            ),
          )
      )
    });

    return {
      responsePromise: uploadPromise,
      progress: progressSubject.asObservable()
    };
  }
}
