import { Component, OnInit, Input, Inject, ElementRef, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MIComponentsModule } from '../components.module';
import { IconButtonModule } from 'src/app/modules/icon-button/icon-button.module';
import { CreateContentStrategy, ParentData } from 'src/app/services/create-content/create-content';
import { SelectedRecipients } from 'src/app/dialogs/share-recipients-dialog/share-recipients-dialog.model';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { ShareToObjType, ShareToObject } from 'src/app/services/sharing/sharing.model';
import { ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { ParentType } from 'src/app/services/yeti-protocol/chatter-api';
import { GroupListItem } from 'src/app/services/groups/group.model';
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import {SharingUIService} from '../../services/sharing/sharing-ui.service';
import { IconComponent } from '../../modules/icon/icon.component';

export enum SelectableParent {
  PRIVATE = 'private',
  PUBLIC = 'public',
  GROUPS_EVENTS = 'groups_events'
}

@Component({
  selector: 'app-create-content-parent-options',
  templateUrl: './create-content-parent-options.component.html',
  styleUrls: ['./create-content-parent-options.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    MIComponentsModule,
    IconButtonModule,
    IconComponent
  ]
})
export class CreateContentParentOptionsComponent implements OnInit, AfterViewInit {

  @Input() createContentStrategy: CreateContentStrategy;
  @Input() infoSheetId: string;
  @Input() selectedParent: SelectableParent;

  @Output() selectedParentChanged: EventEmitter<SelectableParent> = new EventEmitter();
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();

  SelectableParent = SelectableParent;

  selectedMemberGroups: Array<ShareToObject> = [];
  selectedEvents: Array<ShareToObject> = [];

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private appTranslationService: AppTranslationService,
    private dialogs: DialogsUIService,
    private el: ElementRef,
    private infoSheetService: InfoSheetService,
    private sharingUIService: SharingUIService
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    if (this.selectedParent) {
      this.unselectAllRadioButtons();
      this.changeParent(this.selectedParent, true);
      this.forceSelectRadioButton();
    }
  }

  changeParent(parent: SelectableParent, preventShowingSelectGroupsEventsDialog?: boolean): void {
    this.selectedParent = parent;
    this.selectedParentChanged.next(this.selectedParent);

    this.hideInfoSheet();

    if (parent === SelectableParent.GROUPS_EVENTS) {
      if (!preventShowingSelectGroupsEventsDialog) {
        this.selectGroupsEvents();
      }
    } else {

      const parentsType = this.selectedParent === SelectableParent.PRIVATE ? ParentType.CHAT : ParentType.USER;
      const isAlreadySelected = this.createContentStrategy?.parentsData?.[0]?.parentsType === parentsType;

      this.createContentStrategy.parentsData = [{
        parents: [],
        parentsType: parentsType
      }];

      if (!isAlreadySelected)
        this.emitCloseDialog();
    }
  }

  async selectGroupsEvents(): Promise<void> {

    if (this.createContentStrategy?.parentsData?.length) {
      this.createContentStrategy?.parentsData?.forEach(parentsData => {
        if (parentsData?.parentsType === ParentType.GROUP && parentsData?.parents?.length) {
          this.selectedMemberGroups = parentsData?.parents?.map(parentData => {
            return {
              object: parentData,
              type: ShareToObjType.GROUP
            }
          })
        }

        if (parentsData?.parentsType === ParentType.EVENT && parentsData?.parents?.length) {
          this.selectedEvents = parentsData?.parents?.map(parentData => {
            return {
              object: parentData,
              type: ShareToObjType.EVENT
            }
          })
        }
      })
    }

    const selectedRecipients: SelectedRecipients = await this.uiUtilsService.showShareRecipientsDialog(
      this.selectedMemberGroups,
      this.selectedEvents,
      this.createContentStrategy.clinicalCase ?
        this.sharingUIService.getCaseSharingObject(this.createContentStrategy.clinicalCase) : null
    );

    if (!selectedRecipients) {
      return;
    }

    this.selectedMemberGroups = selectedRecipients.selectedMemberGroups || [];
    this.selectedEvents = selectedRecipients.selectedEvents || [];

    if (!this.selectedMemberGroups?.length && !this.selectedEvents?.length) {
      this.selectedParent = null;
      this.selectedParentChanged.next(this.selectedParent);
      return;
    }

    const parentsData: Array<ParentData> = [];

    if (this.selectedMemberGroups?.length) {
      parentsData.push({
        parents: [...this.selectedMemberGroups.map(shareToObject => shareToObject.object as GroupListItem)],
        parentsType: ParentType.GROUP
      })
    }

    if (this.selectedEvents?.length) {
      parentsData.push({
        parents: [...this.selectedEvents.map(shareToObject => shareToObject.object)],
        parentsType: ParentType.EVENT
      })
    }

    this.createContentStrategy.parentsData = parentsData;
    this.emitCloseDialog();
  }

  async checkShouldChangeSelection(event: Event, parent: SelectableParent): Promise<void> {

    let prevSelectedParent: SelectableParent;

    switch (this.selectedParent) {
      case SelectableParent.PRIVATE:
        prevSelectedParent = SelectableParent.PRIVATE;
        break;
      case SelectableParent.PUBLIC:
        prevSelectedParent = SelectableParent.PUBLIC;
        break;
      case SelectableParent.GROUPS_EVENTS:
        prevSelectedParent = SelectableParent.GROUPS_EVENTS;
        break;
    }

    if (this.selectedParent && this.selectedParent !== parent) {
      const shouldProceed = await this.showConfirmDialog();

      if (!shouldProceed) {
        this.uiUtilsService.stopEventPropagation(event);
        this.unselectAllRadioButtons();
        this.selectedParent = prevSelectedParent;
        this.forceSelectRadioButton();
      } else {
        this.unselectAllRadioButtons();
        this.changeParent(parent);
        this.forceSelectRadioButton();
      }
    } else {
      this.changeParent(parent);
    }
  }

  unselectAllRadioButtons(): void {
    const radioButtons = this.el.nativeElement.querySelectorAll('ion-radio');

    radioButtons?.forEach(radio => {
      radio?.classList?.remove('radio-checked');
    });
  }

  forceSelectRadioButton(): void {
    setTimeout(() => {
      const radioShouldBeSelected = this.el.nativeElement.querySelector('.option.selected > .action-container > ion-radio');

      if (radioShouldBeSelected) {
        radioShouldBeSelected?.classList?.add('radio-checked');
      }
    }, 100);
  }

  async showConfirmDialog(): Promise<boolean> {
    const confirmActionKey = 'confirm';
    const noActionKey = 'no';

    const confirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: this.appTranslationService.
            instant('app.component.CreateContentParentOptions.changed-recipient-confirmation-dialog.title')
        },
        message: {
          translationKey: this.appTranslationService.
            instant('app.component.CreateContentParentOptions.changed-recipient-confirmation-dialog.text')
        },
        actions: [
          {
            key: confirmActionKey,
            label: {
              translationKey: this.appTranslationService.
                instant('app.component.CreateContentParentOptions.changed-recipient-confirmation-dialog.confirm-btn')
            },
            className: 'primary'
          },
          {
            key: noActionKey,
            label: {
              translationKey: this.appTranslationService.
                instant('app.component.CreateContentParentOptions.changed-recipient-confirmation-dialog.decline-btn')
            },
            className: 'secondary'
          },
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(confirmDialogData);
    this.hideInfoSheet();
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        this.emitCloseDialog();
        return false;
      }

      if (res?.data?.actionKey === confirmActionKey) {
        return true;
      } else {
        this.emitCloseDialog();
        return false;
      }

    } catch (err) {
      console.error(err);
      this.emitCloseDialog();
      return false;
    }
  }

  hideInfoSheet(): void {
    if (this.infoSheetId) {
      this.infoSheetService.close(this.infoSheetId);
    }
  }

  emitCloseDialog(): void {
    this.closeDialog.emit();
  }

}
